import React from "react";
import { useNavigate } from "react-router-dom";
import "./androidItem.css";
import { notify1 } from "../ToastComponent";

const AndroidItem = ({
  android_img,
  android_btn,
  game_name,
  gameItemId,
  imgNavigate,
  btnNavigate,
}) => {
  const navigate = useNavigate();
  // get user token
  const token = localStorage.getItem("token");
  return (
    <div className="mb-3 game-item">
      <img
        className="game-img"
        src={android_img}
        alt="game_1"
        loading="lazy"
        onClick={() => navigate(`/${imgNavigate}/${gameItemId}`)}
      />
      {game_name ? <span>{game_name}</span> : ""}
      {android_btn ? (
        <button
          className="button"
          onClick={() => {
            if (token) {
              navigate(`/${btnNavigate}/${gameItemId}`);
            } else {
              notify1("Please Signin First");
            }
          }}
        >
          {android_btn}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default AndroidItem;
