import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./gameHistory.css";
import { useTranslation } from "react-i18next";
import { gameHistory } from "../../Utilies/gamerClubApi";

const GameHistory = ({ toggle }) => {
  const { t } = useTranslation();
  // get user token
  const token = localStorage.getItem("token");
  const [usergameHistory, setUserGameHistory] = useState([]);
  const getGameHistory = async (token) => {
    try {
      let result = await gameHistory(token);
      setUserGameHistory(result);
    } catch (error) {}
  };
  useEffect(() => {
    getGameHistory(token);
  }, [token]);

  return (
    <div className={toggle === 4 ? "show-content game-history" : "content"}>
      <div className="row">
        <div className="col">
          <div className="game-content">
            <Table hover responsive="sm">
              <thead>
                <tr>
                  <th className="text-nowrap">{t("Game")}</th>
                  <th className="text-nowrap">{t("Top Score")}</th>
                </tr>
              </thead>
              <tbody>
                {usergameHistory.map((userGame) => (
                  <tr key={userGame.id}>
                    <td>
                      <div className="gap-3 d-flex align-items-center justify-content-center">
                        <img
                          src={userGame.game_thumb}
                          alt="game_img"
                          className="gameHistory-img rounded-circle"
                        />
                        <p className="gameHistory-name">{userGame.game_name}</p>
                      </div>
                    </td>
                    <td>{userGame.score}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameHistory;
