import React, { useState } from "react";
import "./rateUs.css";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import RatingSystem from "../../Components/RatingSystem/RatingSystem";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiSendPlaneFill } from "react-icons/ri";
import Navbar from "../../Components/Navbar/NavbarComp";

const RateUs = () => {
  const navigate = useNavigate();

  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  return (
    <>
      <Navbar />
      <Container style={{ paddingTop: "150px", paddingBottom: "100px" }}>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
          </Col>
        </Row>
        <Row>
          <Col xs={10} md={8} lg={6} className="rate-section my-3 m-auto">
            <h1 className="mt-5 mb-3">rate us </h1>
            <div className="stars mb-4">
              <RatingSystem />
            </div>
            <textarea className="text-msg"></textarea>
            <div className="rate-btns mb-5">
              <button
                className="play-button mb-3"
                onClick={() => navigate("/")}
              >
                <IoMdArrowRoundBack />
                Back
              </button>
              <button
                className="play-button mb-3"
                onClick={() => navigate("/successMsg")}
              >
                Submit
                <RiSendPlaneFill />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RateUs;
