/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Col, Pagination, Row } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import PayOrPlayItem from "../../Components/PayOrPlayItem/PayOrPlayItem";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import { getCategories, searchEngine } from "../../Utilies/api";
import "./payOrPlay.css";
import Navbar from "../../Components/Navbar/NavbarComp";
import SearchInput from "../../Components/SearchInput/SearchInput";
import { ToastContainer } from "react-toastify";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";

const PayOrPlay = () => {
  const [isLoading, setIsLoading] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  let x = useParams();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  // get user token
  const token = localStorage.getItem("token");
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // store categories in state
  const [categories, setCategories] = useState([]);
  // store menu in state
  const [menu, setMenu] = useState([]);
  // get categories
  const { i18n } = useTranslation();
  const [queryInput, setQueryInput] = useState("");
  const getCategoriesCrads = async (search, id, token, page, langType) => {
    try {
      let translations = await getCategories(search, id, token, page, langType);
      i18n.addResourceBundle(i18n.language, "translation", translations);
      setMenu(translations.data.menu);
      setCategories(translations.data.categories.data);
      setTotalPages(translations.data.categories.last_page);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesCrads(queryInput, x.cat, token, currentPage, i18n.language);
  }, [queryInput, x.cat, token, currentPage, i18n.language]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // pagination logic
  let active = currentPage;
  let items = [];
  for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
    items.push(
      <Pagination.Item
        onClick={() => {
          handlePageChange(currentPage);
          scrollToTop();
        }}
        key={currentPage}
        active={currentPage === active}
      >
        {currentPage}
      </Pagination.Item>
    );
  }

  return (
    <>
      <Navbar title={t("pay or play")} />
      <ToastContainer />
      <div className="pay">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
            </div>
          </div>
          <div className="store-content">
            {!isLoading ? (
              <>
                <div className="mx-2 mb-3 games-heading">
                  <h1>{t("explore")}</h1>
                  <div className="games-tabs">
                    <Row>
                      <Col xs={12} md={12} lg={4}>
                        <SearchInput
                          queryInput={queryInput}
                          setQueryInput={setQueryInput}
                          searchPlaceholder={t("search...")}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={12}>
                        <Row>
                          <Col xs={6} md={3} lg={2}>
                            <NavLink className="game-tab" to={"/payorplay/all"}>
                              {t("All")}
                            </NavLink>
                          </Col>
                          {menu.map((menuItem, index) => (
                            <Col xs={6} md={3} lg={2} key={index}>
                              {
                                <NavLink
                                  className="game-tab"
                                  to={`/payorplay/${menuItem}`}
                                  onClick={() => {
                                    setCurrentPage(1);
                                  }}
                                >
                                  {menuItem}
                                </NavLink>
                              }
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="lives show-content">
                  <Row>
                    {categories.map((category, index) => {
                      return (
                        <Col key={index} xs={12} md={6} lg={4}>
                          <PayOrPlayItem category={category} />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} md={12} lg={12}>
                      {totalPages >= 2 ? <Pagination>{items}</Pagination> : ""}
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div
                style={{ height: "100vh" }}
                className="justify-center d-flex align-items-center"
              >
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayOrPlay;
