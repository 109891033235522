import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  deleteUserCartItem,
  getPayMentHubPageLink,
  getUserCart,
  updateCartItem,
} from "../../Utilies/api";
import delete_icon from "../../assets/payment/delete-icon.png";
import arrow from "../../assets/payment/left-arrow.png";
import "./payment.css";
import PaymentContainer from "../PaymentContainer/PaymentContainer";
import { Slide, ToastContainer, toast } from "react-toastify";
import Navbar from "../Navbar/NavbarComp";
import product_img from "../../assets/payment/product.png";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import Footer from "../Footer/Footer";

const SecondCart = () => {
  // show qr code
  const [showQr, setShowQr] = useState([]);
  // toggle tabs
  const [toggle, setToggle] = useState(1);
  const updateToggle = (id) => {
    setToggle(id);
  };
  // credit card
  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });
  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };
  // const notify = (msg) => {
  //   toast.success(msg, {
  //     position: "top-right",
  //     autoClose: 2000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  //     transition: Slide,
  //   });
  // };
  // let sum = 0;
  // const navigate = useNavigate();
  // // cart items
  // const [cart, setCart] = useState([]);
  // const newcart = Object.keys(cart).map((key) => cart[key]);
  // // get user id
  // let userId = JSON.parse(localStorage.getItem("user_id"));
  // // get user token
  // const token = localStorage.getItem("token");

  // // get user cart items
  // async function getCarts(userId, token) {
  //   let result = await getUserCart(
  //     userId,
  //     token,
  //     "https://api.pil.live/api/in-appproduct/cart/show"
  //   );
  //   setCart(result.data);
  // }
  // async function decreaseAmount(object_id, type, amounts) {
  //   amounts = amounts - 1;
  //   let formData = {
  //     player_id: userId,
  //     object_id: object_id,
  //     qty: amounts,
  //     type: type,
  //   };
  //   if (formData.qty >= 1) {
  //     await updateCartItem(formData, token);
  //     setTimeout(() => {
  //       window.location.reload(true);
  //     }, 500);
  //   }
  // }
  // async function increaseAmount(object_id, type, amounts) {
  //   amounts = amounts + 1;
  //   let formData = {
  //     player_id: userId,
  //     object_id: object_id,
  //     qty: amounts,
  //     type: type,
  //   };
  //   if (formData.qty <= 8) {
  //     await updateCartItem(formData, token);
  //     setTimeout(() => {
  //       window.location.reload(true);
  //     }, 500);
  //   }
  // }

  // delete cart item
  // async function deleteCartItem(url, deleteType, voucherId, token) {
  //   let result = await deleteUserCartItem(url, deleteType, voucherId, token);
  //   if (result.status === true) {
  //     notify("Item Deleted Successfully");
  //     setTimeout(() => {
  //       window.location.reload(true);
  //     }, 2000);
  //   }
  // }

  // useEffect(() => {
  //   getCarts(userId, token);
  //   // updateCartItemQty(newcart.object_id, newcart.type, newcart.qty);
  // }, [userId, token]);

  const [paymentSessionId, setPaymentSessionId] = useState("");
  const [showPaymentLink, setShowPaymentLink] = useState(false);
  const number = Math.random();
  // get session_id
  async function paymentHub() {
    let formData = {
      amount: "51",
      redirect_url: "https://portal.pil.live/defaultPayment",
      payload: {
        user_id: "45454",
        user_name: "Ahmed",
        user_email: "ahmed@ahmed.com",
        user_phone: "01065875651",
        products: [
          {
            reference_id: number,
            item_quantity: "1",
            item_description: "game access",
            item_price: "51",
            item_id: "789",
          },
        ],
      },
    };
    const { request_id } = await getPayMentHubPageLink(formData);
    setPaymentSessionId(request_id);
  }

  // open new tab
  const newTab = (url) => {
    window.open(url);
  };

  return (
    <>
      <Navbar title={"payment cart"} />
      <Container>
        <Row style={{ paddingTop: "150px" }}>
          <Col xs={12} md={12} lg={12} className="credit">
            <Row className="payment-content payment-content-reverse">
              <Col xs={12} md={6} lg={6} className="payment-tabs">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="store-tabs">
                      <span
                        style={{ width: "100%" }}
                        onClick={() => updateToggle(1)}
                        className={toggle === 1 ? "active" : ""}
                      >
                        PaymentHub
                      </span>
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    className={
                      toggle === 1 ? "show-cards credit-img" : "credit-img"
                    }
                  >
                    <Row className="text-center">
                      <div
                        style={
                          showQr.length > 0
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      ></div>
                      <Col xs={12} md={12} lg={12}>
                        <button
                          style={
                            !showPaymentLink
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          onClick={() => {
                            paymentHub();
                            setShowPaymentLink(true);
                          }}
                          className="shopping-check"
                        >
                          check out
                        </button>
                        <span
                          onClick={() =>
                            newTab(
                              `http://admin.paymenthub.me/payment-process?ref=${paymentSessionId}`
                            )
                          }
                          style={
                            showPaymentLink
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          className="mb-3 paymob"
                        >
                          go to pay with paymentHub
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* game total payment */}
              <Col xs={12} md={6} lg={6} className="mt-2 games-total">
                <div className="credit-games">
                  <div className="game">
                    <Row>
                      <Col xs={12} md={12} lg={6}>
                        <div className="game-info">
                          <img src={product_img} alt="product" />
                          <div className="game-title">
                            <span>CALL OF DUTY: MODERN </span>
                            <span>WARFARE II - CROSS-GEN </span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <p className="price">51 EGP</p>
                      </Col>
                      <Col xs={6} md={6} lg={3}>
                        <div className="qty">
                          <p>
                            <button>-</button>
                            <span style={{ margin: "0 3px" }}>1</span>
                            <button>+</button>
                          </p>
                          <img src={delete_icon} alt="delete Icon" />
                        </div>
                        {<div className="d-none">51</div>}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="total">
                  <Row>
                    <Col xs={12} md={12} lg={6}>
                      <div className="item">
                        <img src={arrow} alt="" />
                        <p>Add Another Items </p>
                      </div>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <p className="total-price">
                        Total Amount :{" "}
                        <span className="final-price">{51} EGP</span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SecondCart;
