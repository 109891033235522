import React from "react";
import { Card } from "react-bootstrap";
import star from "../../assets/profile/voucher/star.png";
import heart from "../../assets/profile/voucher/heart.png";

const VoucherCard = ({ userVouch, addToCart, token }) => {
  return (
    <Card key={userVouch.id} className="border-2">
      <Card.Img
        variant="top"
        src={userVouch?.product?.productImage}
        alt="productImage"
      />
      <Card.Body>
        <div className="card-titles">
          <Card.Title>{userVouch?.product.productName}</Card.Title>
          {userVouch.serialNumber ? (
            <div className="code">{userVouch.serialNumber}</div>
          ) : (
            ""
          )}
        </div>
        <Card.Text>
          {userVouch?.product?.description.split(" ").join(" ").slice(0, 100) +
            "..."}
        </Card.Text>
        {/* <div className="card-buttons">
          <div
            className="reorder"
            onClick={() => addToCart("cart", token, userVouch.product_id)}
          >
            <span>reorder</span>
            <span>
              {userVouch?.products?.sellPrice}{" "}
              {userVouch?.products?.productCurrency}
            </span>
          </div>
          <div className="star">
            <img src={star} alt="star" />
          </div>
        </div> */}
      </Card.Body>
      {/* <div className="heart">
        <img src={heart} alt="" />
      </div> */}
    </Card>
  );
};

export default VoucherCard;
