import React from "react";
import "./success.css";
import rightCheck from "../../assets/forms/Group.png";
import side_success from "../../assets/forms/side_success_img.png";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import FormsAside from "../../Components/FormsAside/FormsAside";
import { useTranslation } from "react-i18next";

const Success = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="signUp">
      <Row
        className="mx-0"
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <FormsAside side_img={side_success} />
        <Col xs={12} md={7} lg={8} xl={8} className="p-0 success">
          <Row className="m-auto h-100">
            <Col className="m-auto" xs={12} md={8} lg={8} xl={5}>
              <h1>{t("Success")} !</h1>
              <p>{t("Your Account Has Been Created")}</p>
              <div className="check">
                <img src={rightCheck} alt="check" />
              </div>
              <div className="submit-info">
                <button
                  onClick={() => navigate("/", { replace: true })}
                  className="btn-submit w-100"
                >
                  {t("Home Page")}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Success;
