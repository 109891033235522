import React from "react";
import "./formsAside.css";
import { Col, Row } from "react-bootstrap";
import logo from "../../assets/logo2.png";
import { useNavigate } from "react-router-dom";

const FormsAside = ({ side_img }) => {
  const navigate = useNavigate();
  return (
    <Col  className="aside text-center">
      <Row className=" position-relative overflow-hidden">
        {/*  <Col lg={12} > */}
        {/* <p className="text-black description text-sm">Free for 24 hours then AED 2.10 Per Day (VAT Inclusive)</p> */}
        <img
          className="logo w-100 h-100 mt-4"
          src={logo}
          alt="logo"
          onClick={() => navigate("/")}
        />
        {/*  </Col> */}
        {/* <Col lg={12}>
          <img className="angry_birds" src={side_img} alt="angry_birds" />
        </Col> */}
      </Row>
    </Col>
  );
};

export default FormsAside;
