import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import delete_icon from "../../assets/payment/delete-icon.png";
import arrow from "../../assets/payment/left-arrow.png";
import "./payment.css";
import { ToastContainer } from "react-toastify";
import Navbar from "../Navbar/NavbarComp";
import { useTranslation } from "react-i18next";
import Loading from "../Loading/Loading";
import { notify } from "../ToastComponent";
import {
  deleteUserCartItem,
  getUserCart,
  updateCartItem,
} from "../../Utilies/gamerClubApi";

const PaymentContent = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  let sum = 0;
  const navigate = useNavigate();
  // cart items
  const [cart, setCart] = useState([]);
  // get user token
  const token = localStorage.getItem("token");

  // get user cart items
  async function getCarts(token) {
    try {
      let result = await getUserCart(token);
      setCart(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const updateQuantity = async (itemId, type, newQuantity) => {
    let formData = {
      object_id: itemId,
      qty: newQuantity,
      type: type,
    };
    await updateCartItem(formData, token);
    getCarts(token);
  };

  // delete cart item
  async function deleteCartItem(deleteType, voucherId, token) {
    try {
      setCart(cart.filter((item) => item.id !== voucherId));
      await deleteUserCartItem(deleteType, voucherId, token);
      notify("Item Deleted Successfully");
    } catch (error) {}
  }

  useEffect(() => {
    getCarts(token);
  }, [token]);

  return (
    <>
      <Navbar title={t("payment cart")} />
      <Container>
        <Row
          style={{
            paddingTop: "100px",
          }}
        >
          <ToastContainer />
          <Col
            xs={12}
            md={12}
            lg={12}
            className="credit"
            style={{
              minHeight: "445px",
            }}
          >
            <Row className="payment-content payment-content-reverse">
              {/* <Col xs={12} md={12} lg={6} className="payment-tabs">
                <PaymentContainer cart={cart} setCart={setCart} />
              </Col> */}
              {/* game total payment */}
              {isLoading ? (
                <Loading />
              ) : (
                <Col xs={12} md={12} lg={12} className="mt-2 games-total">
                  <div className="credit-games">
                    {cart.length > 0 ? (
                      cart.map((cartItem) => (
                        <div className="game" key={cartItem.id}>
                          <Row>
                            <Col xs={12} md={6} lg={6}>
                              <div className="game-info">
                                <img
                                  src={
                                    cartItem[cartItem.type].image
                                      ? `http://api.gamerclub.live${
                                          cartItem[cartItem.type].image
                                        }`
                                      : cartItem[cartItem.type].productImage
                                  }
                                  alt={cartItem[cartItem.type]?.name?.en}
                                />
                                <div className="game-title">
                                  <span>
                                    {cartItem[cartItem.type]?.title}
                                    {cartItem[cartItem.type]?.name?.en}
                                    {cartItem[cartItem.type]?.productName}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                              <p className="price">
                                {cartItem[cartItem.type]?.sellPrice
                                  ? cartItem[cartItem.type]?.sellPrice
                                  : cartItem[cartItem.type].price *
                                    cartItem.qty}{" "}
                                {cartItem[cartItem.type].currency ?? "EGP"}{" "}
                                {/* {cartItem[cartItem.type].productCurrency} */}
                              </p>
                            </Col>
                            <Col xs={6} md={3} lg={3}>
                              <div className="qty">
                                <p>
                                  <button
                                    style={
                                      cartItem.qty <= 1
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() => {
                                      if (cartItem.qty > 1) {
                                        updateQuantity(
                                          cartItem[cartItem.type]?.id,
                                          cartItem?.type,
                                          cartItem.qty - 1
                                        );
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                  <span style={{ margin: "0 3px" }}>
                                    {cartItem.qty}
                                  </span>
                                  <button
                                    style={
                                      cartItem.qty >= 9
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "pointer" }
                                    }
                                    onClick={() => {
                                      if (cartItem.qty < 9) {
                                        updateQuantity(
                                          cartItem[cartItem["type"]]?.id,
                                          cartItem?.type,
                                          cartItem.qty + 1
                                        );
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </p>
                                <img
                                  onClick={() =>
                                    deleteCartItem(
                                      "delete-one",
                                      cartItem.id,
                                      token
                                    )
                                  }
                                  src={delete_icon}
                                  alt="delete Icon"
                                />
                              </div>
                              {
                                <div className="d-none">
                                  {
                                    (sum +=
                                      (cartItem[cartItem.type]?.sellPrice
                                        ? cartItem[cartItem.type]?.sellPrice
                                        : cartItem[cartItem.type].price * 0.8) *
                                      cartItem.qty)
                                  }
                                </div>
                              }
                            </Col>
                          </Row>
                        </div>
                      ))
                    ) : (
                      <h2 className="empty-cart">
                        {t("There Is No Items In Your Shopping Cart !")}
                      </h2>
                    )}
                  </div>
                  <div className="total">
                    <Row>
                      <Col xs={12} md={6} lg={6}>
                        <div
                          className="item"
                          onClick={() => navigate("/payorplay/all")}
                        >
                          <img
                            style={
                              localStorage.getItem("i18nextLng") === "ar"
                                ? { transform: "rotate(180deg)" }
                                : {}
                            }
                            src={arrow}
                            alt="arrow"
                          />
                          <p>{t("Add Another Items")}</p>
                        </div>
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <p className="total-price">
                          {t("Total Amount")} :{" "}
                          <span className="final-price">
                            {sum.toFixed(2)} EGP
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentContent;
