import { Navigate, useLocation } from "react-router-dom";

const RequiredAuth = ({ children }) => {
  // errors messages state
  const token = localStorage.getItem("token");
  const location = useLocation();
  if (!token) {
    return (
      <Navigate
        to="/signIn"
        replace={true}
        state={{ path: location.pathname }}
      />
    );
  } else {
  }
  return children;
};

export default RequiredAuth;
