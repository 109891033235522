import React, { useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { BarcodeImg, getPosRefNum, getUserData } from "../../Utilies/api";
import { notify1 } from "../ToastComponent";
import { useTranslation } from "react-i18next";

const SinglePayment = ({ live, qty }) => {
  const { t } = useTranslation();
  // autofill username input deped on userid input
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState("");
  const handleUserIdChange = async (event) => {
    const enteredUserId = event.target.value;
    setUserId(enteredUserId);
    try {
      const response = await getUserData(enteredUserId);
      setUserData(response.data);
      setUserName(response.data.name);
    } catch (error) {
      console.error("Error fetching username:", error);
      setUserData("");
      setUserName(""); // Clear username if error occurs
    }
  };
  // state to show fawry ref_num
  const [showRef_Num, setShowRef_Num] = useState(false);
  const [ref_Num, setRef_Num] = useState("");

  // get fawry ref num
  const getFawryRefNum = async () => {
    let formData = {
      customer_id: userId,
      customer_name: userData?.name,
      customer_email: userData?.email,
      products: [
        {
          object_id: live?.id,
          type: live?.type === 1 ? "product" : "subscription",
          qty: +qty,
        },
      ],
    };
    try {
      if (userId && userName) {
        let result = await getPosRefNum(formData);
        setRef_Num(result.data.ref_num);
        if (result.status === true) {
          setShowRef_Num(true);
        }
      } else {
        notify1("Please Enter User ID");
      }
    } catch (error) {
      notify1(error.response.data.error);
    }
  };
  // show qr code
  const [showQr, setShowQr] = useState([]);
  const [showBtn, setShowBtn] = useState(true);

  // get bar code function
  const getBarCode = async () => {
    try {
      let formData = {
        customer_id: userId,
        customer_name: userData?.name,
        customer_email: userData?.email,
        products: [
          {
            object_id: live.sku
              ? live.sku
              : live.productId
              ? live.productId
              : live.id,
            type: live.sku
              ? "product"
              : live.productId
              ? "subscription"
              : "voucher",
            qty: +qty,
          },
        ],
      };
      if (userId && userName) {
        let result = await BarcodeImg(formData);
        setShowQr(result.data.qr_code);
        setShowBtn(!showBtn);
      } else {
        notify1("Please Enter User ID");
      }
    } catch (err) {
      notify1(err.response.data.error);
    }
  };
  // toggle tabs
  const [toggle, setToggle] = useState(2);
  const updateToggle = (id) => {
    setToggle(id);
  };
  return (
    <Row>
      {/* store tabs */}
      <Col xs={12} md={12} lg={12}>
        <div className="my-2 store-tabs">
          {/* <span
            onClick={() => updateToggle(1)}
            className={toggle === 1 ? "active" : ""}
          >
            VisaCard
          </span> */}
          <span
            onClick={() => updateToggle(2)}
            className={toggle === 2 ? "active" : ""}
          >
            {t("E-Wallet")}
          </span>
          <span
            onClick={() => updateToggle(3)}
            className={toggle === 3 ? "active" : ""}
          >
            {t("fawry")}
          </span>
        </div>
      </Col>
      {/* store payment content */}
      {/* <Col
        xs={12}
        md={12}
        lg={12}
        className={toggle === 1 ? "show-cards credit-img" : "credit-img"}
      ></Col> */}

      <Col
        xs={12}
        md={12}
        lg={12}
        className={toggle === 2 ? "show-cards credit-img" : "credit-img"}
      >
        {showQr.length > 0 ? (
          <div>
            <Col xs={12} md={12} lg={12}>
              <img className="qr-code" src={showQr} alt="credit" />
            </Col>
            <Col xs={12} md={12} lg={12}>
              <h4 className="my-3 text-center qr-text">
                scan QR code with your company wallet
                <br /> (Ana Vodafone, e&, My Orange, My We)
              </h4>
            </Col>
          </div>
        ) : (
          <Col>
            <div className="card-num">
              <label htmlFor="userID1">{t("User ID")}</label>
              <input
                required={true}
                style={{ textAlign: "left", paddingLeft: "10px" }}
                id="userID1"
                className="credit-inputs"
                type="tel"
                name="userID1"
                placeholder={t("enter user id")}
                autoFocus={true}
                value={userId}
                onChange={handleUserIdChange}
              />
            </div>
            <div className="card-num">
              <label htmlFor="userName1">{t("User Name")}</label>
              <input
                required={true}
                id="userName1"
                className="credit-inputs"
                type="text"
                name="userName1"
                readOnly={true}
                value={userData?.name}
              />
            </div>
          </Col>
        )}
        <Col xs={12} md={12} lg={12}>
          {showQr.length > 0 ? (
            ""
          ) : (
            <button
              onClick={() => {
                setShowQr(true);
                getBarCode();
              }}
              className="mb-4 shopping-check"
            >
              {t("check out")}
            </button>
          )}
        </Col>
      </Col>

      <Col
        xs={12}
        md={12}
        lg={12}
        className={
          toggle === 3
            ? "show-cards credit-img paymob text-white"
            : "credit-img"
        }
      >
        {!showRef_Num ? (
          <>
            <div className="card-num">
              <label htmlFor="userID">{t("User ID")}</label>
              <input
                required={true}
                id="userID"
                className="credit-inputs"
                type="tel"
                name="userID"
                autoFocus={true}
                value={userId}
                onChange={handleUserIdChange}
              />
            </div>
            <div className="card-num">
              <label htmlFor="userName">{t("User Name")}</label>
              <input
                required={true}
                id="userName"
                className="credit-inputs"
                type="text"
                name="userName"
                readOnly={true}
                value={userData?.name}
              />
            </div>
          </>
        ) : (
          ""
        )}
        <button
          type="button"
          style={
            !showRef_Num
              ? { display: "block", textDecoration: "none" }
              : { display: "none" }
          }
          onClick={() => {
            getFawryRefNum();
          }}
          className="shopping-check"
        >
          {t("GET FAWRY REF_NUM")}
        </button>
        <Alert
          style={
            !showRef_Num
              ? { display: "none" }
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "black",
                  fontWeight: "bold",
                  marginBottom: "0",
                }
          }
          variant="info"
        >
          <p
            className="mb-0 "
            style={{ color: "black", textDecoration: "none" }}
          >
            Fawry Reference Number : <span>{ref_Num}</span>
          </p>
          {/* <IoCopyOutline /> */}
        </Alert>
      </Col>
    </Row>
  );
};

export default SinglePayment;
