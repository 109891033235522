import "./PrivacyTerms.css";

const Privacy = () => {
  return (
    <div>
      {" "}
      <body className="privacy">
        <header>
          <h1>Privacy Policy</h1>
          <p>
            <strong>Effective Date: March 29, 2024</strong>
          </p>
        </header>

        <main>
          <section>
            <h2>Introduction</h2>
            <p>
              Gamer Club ("we," "us," or "our") respects the privacy of our
              users ("you" or "your"). This Privacy Policy describes how we
              collect, use, disclose, and secure your personal information when
              you use Service_name (the "Service").
            </p>
          </section>

          <section>
            <h2>Information We Collect</h2>
            <p>
              We collect several types of information for various purposes to
              improve our Service and to provide you with the best possible
              experience.
            </p>

            <article>
              <h3>Personal Information</h3>
              <p>
                When you use the Service, we may collect personal information
                such as your name, email address, and job title. This
                information is typically collected when you register for the
                Service or use certain features.
              </p>
            </article>

            <article>
              <h3>Usage Data</h3>
              <p>
                We may also collect usage data to improve the Service and
                personalize your experience.
              </p>
            </article>

            <article>
              <h3>Device Information</h3>
              <p>
                We may collect details about the device you use to access the
                Service, such as device type, operating system, IP address, and
                unique device identifier. This information is used for
                troubleshooting and improving Service security.
              </p>
            </article>
          </section>

          <section>
            <h2>How We Use Your Information</h2>
            <p>We use the collected information for purposes such as:</p>
            <ul>
              <li>Providing and operating the Service</li>
              <li>Processing your requests</li>
              <li>Personalizing your experience</li>
              <li>
                Communicating with you about the Service, including updates,
                security alerts, and support messages
              </li>
              <li>Tracking and analyzing usage data to enhance the Service</li>
              <li>Complying with legal and regulatory requirements</li>
            </ul>
          </section>

          <section>
            <h2>Sharing Your Information</h2>
            <p>
              We may share your information with third-party service providers
              to help us operate the Service. These providers are obligated to
              protect your information and use it only as instructed.
            </p>
            <p>
              Additionally, we may disclose information as required by law or to
              protect the rights, property, or safety of ourselves or others.
            </p>
          </section>

          <section>
            <h2>Data Security</h2>
            <p>
              We take reasonable steps to secure your information from
              unauthorized access, disclosure, alteration, or destruction.
              However, no internet transmission or storage system is completely
              secure. Therefore, we cannot guarantee absolute security.
            </p>
          </section>

          <section>
            <h2>Your Choices</h2>
            <p>You have several options regarding your information:</p>
            <ul>
              <li>
                Access and update your personal information through the Service
                settings.
              </li>
              <li>Opt-out of receiving marketing communications from us.</li>
              <li>
                Delete your account at any time through the Service settings.
              </li>
            </ul>
          </section>

          <section>
            <h2>Children's Privacy</h2>
            <p>
              Our Service is not directed to children under the age of 13. We do
              not knowingly collect personal information from children under 13.
              If you believe your child has provided us with personal
              information, please contact us, and we will take steps to remove
              the information.
            </p>
          </section>

          <section>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              We may update this Privacy Policy periodically. We will notify you
              of any changes by posting the updated Privacy Policy on the
              Service.
            </p>
          </section>
        </main>
      </body>
    </div>
  );
};
export default Privacy;
