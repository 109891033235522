import { axios } from "../api/axiosInterceptor";

// import axios from "axios";
const base_url = "api.gamerclub.live";

// get signup api
export let signupForm = async (formData) => {
  let { data } = await axios.post(
    "http://api.gamerclub.live/api/register",
    formData
  );
  return data;
};

// get signin api
export let signinForm = async (formData) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/auths/verify-otp",
    formData
  );
  return data;
};

// get otp code from api
export let otpCode = async (phoneNum) => {
  let { data } = await axios.get(
    `https://api.pil.live/api/auths/generate/verification?phone=${phoneNum}&country_code=20&language=en`
  );
  return data;
};

// send && resend otp code from api
export let linkOtpCode = async (otpFunName, phoneNum) => {
  let { data } = await axios.post(
    `https://api.pil.live/api/auths/${otpFunName}`,
    phoneNum
  );
  return data;
};

// get category details
export let getUserDetails = async (userId, token) => {
  let { data } = await axios.get(`https://api.pil.live/api/players/${userId}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// get category details
export let getUserCoins = async (token) => {
  let { data } = await axios.get(`${base_url}/api/wallet`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  console.log(data);
  return data;
};

// get categories
export let getCategories = async (querySearch, id, token, page, langType) => {
  let url = `http://api.gamerclub.live/api/voucher-details/pay-or-play?page=${page}&search=${querySearch}`;

  if (id !== "all" && id !== undefined) {
    url = url + `&menu=${id}`;
  }
  let { data } = await axios.get(url, [
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "Accept-Language": langType,
      },
    },
  ]);
  return data;
};

// get category details
export let getCategoryDetails = async (categoryId, token, langType) => {
  let { data } = await axios.get(
    `http://api.gamerclub.live/api/voucher-details/${categoryId}`,
    [
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "Accept-Language": langType,
        },
      },
    ]
  );
  return data;
};

// get store cards
export let getStoreCrads = async () => {
  let { data } = await axios.get(
    `https://api.pil.live/api/in-appproduct/products`
  );
  return data;
};

// get user cart & wishlist items
export let getUserCart = async (userId, token, url) => {
  let { data } = await axios.get(`${url}/${userId}`, [
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    },
  ]);
  return data;
};

// get user vouchers items
export let getUserVouchers = async (userId, token) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/in-appproduct/voucher/my_vouchers",
    userId,
    [
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      },
    ]
  );
  return data;
};
// add an item to cart
export let purchaseVoucher = async (voucher, token) => {
  let { data } = await axios.post(
    `https://api.gamerclub.live/api/voucher-details/purchase/${voucher}`,
    {},

    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// add an item to cart
export let postCartItem = async (formData, sectionType, token) => {
  let { data } = await axios.post(
    `https://api.pil.live/api/in-appproduct/${sectionType}/store`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// add an item to cart
export let postWishListItem = async (formData, token) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/in-appproduct/wishlist/store",
    formData,
    [
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      },
    ]
  );
  return data;
};

// delete user cart & wishlist item
export let deleteUserCartItem = async (url, deleteType, voucherId, token) => {
  let { data } = await axios.delete(`${url}/${deleteType}/${voucherId}`, [
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    },
  ]);
  return data;
};

// delete user wishlist item
export let deleteUserWishlistItem = async (voucherId, token) => {
  let { data } = await axios.delete(
    `https://api.pil.live/api/in-appproduct/wishlist/delete/${voucherId}`,
    [
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      },
    ]
  );
  return data;
};
// delete user cart items
export let deleteUserCartItems = async (userId, token) => {
  let { data } = await axios.delete(
    `https://api.pil.live/api/in-appproduct/cart/delete-all/${userId}`,
    [
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      },
    ]
  );
  return data;
};

// update an item in cart
export let updateCartItem = async (formData, token) => {
  let { data } = await axios.patch(
    "https://api.pil.live/api/in-appproduct/cart/update",
    formData,
    [
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      },
    ]
  );
  return data;
};

// get html5  games
export let getHtmlGames = async (user_id) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/web-games?playerid=${user_id}`
  );
  return data;
};

// get android  games
export let getAndroidGames = async (user_id) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/web-games/android?playerid=${user_id}`
  );
  return data;
};
// get game details
export let getGameDetails = async (gameId, token) => {
  let { data } = await axios.get(
    `https://api.gamerclub.live/api/games/${gameId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get all battles
export let getBattles = async (userId, token) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/web-contests?playerId=${userId}&type=battlle`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get all tournaments
export let getTournaments = async (userId, token) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/web-contests?playerId=${userId}&type=tournament`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get tournament details & leaderboard
export let getTournamentDetails = async (tournamentId, userId, token) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/web-contests/${tournamentId}?playerId=${userId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get my tournaments
export let getMyTournaments = async (userId, token) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/contests/private/${userId}/all`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get create tournament modal details
export let getCreateTournamentDetails = async (token) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/contests/private`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// create tournament
export let createTournamnet = async (formData, userId, token) => {
  let { data } = await axios.post(
    `https://test-dev-console.pil.live/api/contests/private/${userId}?language=en`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// check code
export let checkTournamentCode = async (formData, userId, token) => {
  let { data } = await axios.post(
    `https://test-dev-console.pil.live/api/contests/private/${userId}/can-join?language=en`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// check tournament join
export let checkTournamentJoin = async (formData, token) => {
  let { data } = await axios.post(
    "https://test-dev-console.pil.live/api/web-contests/join",
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get profile game history
export let gameHistory = async (formData, token) => {
  let { data } = await axios.post(
    "https://test-dev-console.pil.live/api/web-games-result/get-game-result",
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export let searchEngine = async (queryName) => {
  let { data } = await axios.get(
    `https://test-dev-console.pil.live/api/web-games?search=${queryName}`
  );
  return data;
};
// check code
export let BarcodeImg = async (formData) => {
  let { data } = await axios.post(
    `https://api.pil.live/api/in-appproduct/payment/fawry/pay/qr`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// check code
export let SingleBarcodeImg = async (formData, token) => {
  let { data } = await axios.post(
    `https://api.pil.live/api/in-appproduct/payment/fawry/pay-now/qr`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get paymob redirect link
export let getPayMobPageLink = async (formData, token) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/in-appproduct/payment/paymob/pay/wallet",
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// single payMob
export let getSinglePayMobPageLink = async (formData, token) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/in-appproduct/payment/paymob/pay-now/wallet",
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get paymentHub redirect link
export const getPayMentHubPageLink = async (formData) => {
  const url = "https://api.paymenthub.me";
  const { data } = await axios.post(
    `${url}/api/payments/init/01hrvxr85a2hftr69yzgrnhz3v`,
    formData
  );
  return data;
};

// get fawry pos reference number
export let getPosRefNum = async (formData) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/in-appproduct/payment/fawry/pay/ref_num",
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get fawry single payment pos reference number
export let getSinglePosRefNum = async (formData, token) => {
  let { data } = await axios.post(
    "https://api.pil.live/api/in-appproduct/payment/fawry/pay-now/ref_num",
    formData,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get userdata depend on id
export let getUserData = async (userId) => {
  const { data } = await axios.get(
    `https://api.pil.live/api/in-appproduct/player-info/${userId}`
  );
  return data;
};
