import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import "./GameItemDetails.css";
import "./playNowBtn.css";
import "./rateBtn.css";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";
import { getGameDetails } from "../../Utilies/gamerClubApi";
import { SlEnergy } from "react-icons/sl";
import { BsStars } from "react-icons/bs";

const HtmlGameDetails = () => {
  const { t } = useTranslation();
  // get game id
  let { gameId } = useParams();
  // get user token
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  // game details state
  const [gameDetails, setGameDetails] = useState({});

  useEffect(() => {
    const gameItemDetails = async (gameId) => {
      try {
        const result = await getGameDetails(gameId);
        setGameDetails(result);
      } catch (error) {}
    };
    gameItemDetails(gameId);
  }, [gameId]);

  return (
    <>
      <Navbar />
      <div
        className="game-details"
        style={{ paddingTop: "150px", paddingBottom: "50px" }}
      >
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} lg={4}>
              <img
                className="w-100 gameDetails-img"
                src={gameDetails?.thumb}
                alt={gameDetails?.name}
              />
              {/* <span className="game-date">Starts in 1D 10H 21M</span>
              <span>2/11/2022 :00 PM EDT</span> */}
            </Col>
            <Col xs={12} md={8} lg={8} className="game-section">
              <div className="game-heading">
                <h2>{gameDetails?.name}</h2>
                <p className="mt-4 game-level">
                  Category : {gameDetails?.cat_name} - Type :{" "}
                  {gameDetails?.type}
                </p>
              </div>
              <div className="game-info">
                <p>
                  {gameDetails?.description?.length > 100
                    ? gameDetails?.description.slice(0, 250) + "..."
                    : gameDetails?.description}
                  ...
                </p>
              </div>
              <Row className="game-btns">
                <Col xs={6} md={4} lg={3}>
                  <Link
                    className="mb-3 play-button"
                    to={token ? `/play/${gameDetails.id}` : "/signin"}
                  >
                    <SlEnergy />
                    {t("play now")}
                  </Link>
                </Col>
                <Col xs={6} md={4} lg={3}>
                  <button
                    onClick={() => {
                      navigate("/html5games");
                    }}
                    className="mb-3 play-button"
                  >
                    {t("back")}
                  </button>
                </Col>
                <Col xs={6} md={4} lg={3}>
                  <button
                    className="mb-3 rate-btn"
                    onClick={() => navigate("/rateUs")}
                  >
                    <BsStars />
                    {t("rate us")}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HtmlGameDetails;
