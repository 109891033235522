import React from "react";
import Slider from "react-slick";
import "./slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import token from "../../assets/gamer_token.svg";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const GamesSlider = ({ vouchers, lang }) => {
  console.log(lang);
  const { t } = useTranslation();
  const navigate = useNavigate();
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="games-slider">
      <h1 className="slider-title">
        {t("pay or")} <span>{t("play")}</span>
      </h1>
      <Container fluid>
        <Slider {...settings}>
          {vouchers.map((vouch) => {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/voucher/${vouch.id}`)}
                key={vouch.id}
              >
                <div className="card">
                  <Row>
                    <Col lg={6}>
                      <img
                        src={vouch.image}
                        alt="spider"
                        className="card-image w-100"
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="card-body">
                        <h5 className="card-title">
                          {lang === "en" ? vouch.name_en : vouch.name_ar}
                        </h5>
                        <p className="card-text card-desc">
                          {lang === "en"
                            ? vouch.description_en
                                .split(" ")
                                .slice(0, 30)
                                .join(" ")
                            : vouch.description_ar
                                .split(" ")
                                .slice(0, 30)
                                .join(" ")}
                          ...
                        </p>
                        <div className="tokens">
                          {vouch.token_price}
                          <img src={token} alt="" />
                        </div>
                        {/* <div className="text-white price">
                          {vouch.voucher_least_price} {vouch.voucher_currency}
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </Slider>
      </Container>
      <button
        className="text-white seeAll-btn"
        onClick={() => navigate("/payorplay/all")}
      >
        <p>{t("see all")}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={4}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </button>
    </div>
  );
};

export default GamesSlider;
