import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlineError } from "react-icons/md";

const PaymentFailed = ({ props }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <Col lg={3}></Col>
        <Col xs={12} lg={6}>
          <div className="pay-succ">
            <MdOutlineError className="error" />
            <h1>payment Failed !</h1>
            <span>Please try again</span>
            <div className="submit-info">
              <button onClick={() => navigate("/")} className="btn-submit">
                Go Home Page
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentFailed;
