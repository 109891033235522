import React, { useState } from "react";
import "./info.css";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Heading from "../Heading/Heading";
import { notify, notify1 } from "../ToastComponent";
import { Link } from "react-router-dom";
import bag_cart from "../../assets/cart.png";
import Slider from "react-slick";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { postCartItem } from "../../Utilies/gamerClubApi";

const Info = ({ packages }) => {
  const { t } = useTranslation();
  // show payment modal
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1444,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // get user token
  const token = localStorage.getItem("token");

  // add to cart
  const [arr, setArr] = useState([]);
  async function addToCart(sectionType, token, productId, cartItemType) {
    setArr([...arr, productId]);
    let formData = {
      items: [
        {
          object_id: productId,
          qty: 1,
          type: cartItemType,
        },
      ],
    };
    await postCartItem(formData, sectionType, token);
  }

  return (
    <div className="store sub-package">
      <ToastContainer />
      <Container fluid>
        <Heading
          title={t("Memberships And Packages")}
          btn_title={t("see all")}
          headingNavigate={"/store"}
        />
        <hr />
        <div className="info">
          <div className="slider-container">
            <Row>
              <Slider {...settings}>
                {packages.map((item, index) => {
                  return (
                    <Col xs={12} md={6} lg={6} xl={4} key={item.id}>
                      <div className="mx-2 gold-card">
                        <img
                          loading="lazy"
                          src={`http://api.gamerclub.live${item?.image}`}
                          alt="icon"
                        />
                        <div className="lives-price">
                          <Link to={"/store"} className="lives-number">
                            {item?.name}
                          </Link>
                          <span
                            className="lives-price"
                            style={{ flexDirection: "column", gap: "5px" }}
                          >
                            <div>
                              <span>{item?.price}</span> EGP
                            </div>
                          </span>
                        </div>
                        <div className="buy">
                          <div
                            className="add-to-cart"
                            onClick={() => {
                              if (token) {
                                if (!arr.includes(item.id)) {
                                  addToCart(
                                    "cart",
                                    token,
                                    item.id,
                                    item.type === 1 ? "product" : "subscription"
                                  );
                                  notify("Your Item Added To Cart");
                                } else {
                                  notify1("Already Exist In Cart");
                                }
                              } else {
                                notify1("Please Sign In First");
                              }
                            }}
                          >
                            <img src={bag_cart} alt="cart" />
                            <span>{t("add to cart")}</span>
                          </div>
                          <button
                            onClick={() => {
                              handleShow(index);
                            }}
                            className="btn-buy"
                          >
                            {item.sku ? t("buy now") : t("subscribe now")}
                          </button>
                          {/* <Modal
                            show={showId === index && show}
                            onHide={handleClose}
                            keyboard={false}
                          >
                            <Modal.Body className="voucher-modal">
                              <Row>
                                <Col lg={6}>
                                  <img
                                    className="w-100"
                                    src={gamer_token}
                                    alt="itemship"
                                  />
                                </Col>
                                <Col lg={6}>
                                  <p className="member-title">{item.title}</p>
                                  <p className="member-description">
                                    {item?.description?.slice(0, 95)}
                                  </p>
                                  <p className="mb-3 member-price">
                                    <span>
                                      {item.price[0]
                                        ? (
                                            (item.price[0]?.price.units +
                                              "." +
                                              item?.price[0]?.price.nanos /
                                                10000000) *
                                            0.8
                                          ).toFixed(2)
                                        : (
                                            (item?.price?.priceMicros /
                                              1000000) *
                                            0.8
                                          ).toFixed(2)}
                                    </span>{" "}
                                    <span>
                                      {item?.price[0]?.price.currencyCode ||
                                        item.price.currency}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="payment-tabs"
                                >
                                  <SinglePayment live={item} qty={"1"} />
                                </Col>
                              </Row>
                            </Modal.Body>
                          </Modal> */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Slider>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Info;
