import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css'
import "react-toastify/dist/ReactToastify.css";
import "./i18n.js";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
