import React, { useState } from "react";
import "./tabs.css";
import Voucher from "../Voucher/Voucher";
import GameHistory from "../GameHistory/GameHistory";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import WishListTable from "../WishListTable/WishListTable";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Subscription from "../MySubscription/MySubscription";

const ProfileTabs = ({ userData }) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(1);
  const updateToggle = (id) => {
    setToggle(id);
  };
  return (
    <Container fluid>
      <div className="mt-3 tabs">
        <ul>
          <li
            onClick={() => updateToggle(1)}
            className={toggle === 1 ? "active" : ""}
          >
            {t("voucher")}
          </li>
          {/* <li
            onClick={() => updateToggle(3)}
            className={toggle === 3 ? "active" : ""}
          >
            {t("wishlist")}
          </li> */}
          <li
            onClick={() => updateToggle(4)}
            className={toggle === 4 ? "active" : ""}
          >
            {t("games history")}
          </li>
          <li
            onClick={() => updateToggle(5)}
            className={toggle === 5 ? "active" : ""}
          >
            {t("My Subscription")}
          </li> 
          {/* <li
            onClick={() => updateToggle(5)}
            className={toggle === 5 ? "active" : ""}
          >
            {t("payment history")}
          </li> */}
        </ul>
      </div>
      <Voucher toggle={toggle} />
      {/* <WishListTable toggle={toggle} /> */}
      <GameHistory toggle={toggle} />
      <Subscription toggle={toggle} userDetails={userData}/>
      {/* <PaymentHistory toggle={toggle} /> */}
    </Container>
  );
};

export default ProfileTabs;
