import React, { useEffect, useState } from "react";
import "./OtpCode.css";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { notify, notify1 } from "../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import FormsAside from "../../Components/FormsAside/FormsAside";
import otp_side from "../../assets/forms/side_otp_img.png";
import { useTranslation } from "react-i18next";
import { linkOtpCode } from "../../Utilies/gamerClubApi";

const OtpCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // get user phone number to local storage
  let phone_number = localStorage.getItem("userPhone");

  const [lodaing, setLodaing] = useState(true);
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);

  // function to resend otp code
  const resendOtp = async () => {
    setMinutes(0);
    setSeconds(20);
    setOtp("");
    setLodaing(true);
    let formData = {
      phone_email: phone_number,
    };
    await linkOtpCode("resend-otp", formData);
    // localStorage.setItem("userOtp", response.data.message);
  };

  const verifyOtp = async () => {
    try {
      setLodaing(false);
      let formData = {
        phone: phone_number,
        otp: otp,
      };
      let result = await linkOtpCode(formData);
      console.log(result);
      // success alert message
      notify("OTP Code Is Correct");
      localStorage.setItem("user_id", result?.user?.id);
      // store token to local storage
      localStorage.setItem("token", result?.access_token);
      localStorage.setItem("userImage", JSON.stringify(result?.user?.avatar));
      setTimeout(() => {
        navigate("/success", { replace: true });
      }, 2500);
    } catch (error) {
      if (error) {
        notify1(error?.response?.data?.message);
      }
      setLodaing(true);
      setOtp("");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // decrease seconds if greater than 0
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      // decrease minutes if seconds reaches 0
      if (seconds === 0) {
        if (minutes === 0) {
          // stop minutes and seconds when its reach 0
          clearInterval(interval);
        } else {
          // reset seconds to number and decrease minutes by 1
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      // cleanup: stop the interval when the component unmount
      clearInterval(interval);
    };
  }, [minutes, seconds]); // re-run this effect whenever seconds changes

  return (
    <div className="signUp">
      <Row
        className="mx-0"
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <ToastContainer />
        <FormsAside side_img={otp_side} />
        <Col xs={12} md={7} lg={8} xl={8} className="p-0 otp">
          <Row className="m-auto w-100 h-100">
            <Col className="m-auto" xs={12} md={8} lg={5}>
              <h1>{t("OTP VERIFICATION")}</h1>
              <h2>
                {t("Enter the OTP sent to")}{" "}
                <span className="user-phone">{phone_number}</span>
              </h2>
              <OTPInput
                inputType="number"
                containerStyle={{ justifyContent: "center" }}
                inputStyle={{
                  width: "40px",
                  height: "50px",
                  justifyContent: "space-between",
                  border: "2px solid black",
                }}
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input required {...props} />}
                shouldAutoFocus={true}
              />
              <p className="mt-4">
                {t("Time Remaining :")}{" "}
                <span>
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </p>
              <p className="my-4">
                {t("Don’t receive code ?")}{" "}
                <button
                  onClick={resendOtp}
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {t("Resend OTP")}
                </button>
              </p>
              <div className="submit-info">
                <button
                  onClick={() => verifyOtp()}
                  className="btn-submit w-100"
                >
                  {lodaing ? (
                    t("Verify Otp")
                  ) : (
                    <i className="fas fa-spinner fa-spin"></i>
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OtpCode;
