import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CountDown = ({ inputDate }) => {
  const { t } = useTranslation();
  // countdown
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      const changingDate = new Date(inputDate);
      const currentDate = new Date();
      const totalSeconds = (changingDate - currentDate) / 1000;
      if (totalSeconds > 0) {
        setDays(formatTime(Math.floor(totalSeconds / 3600 / 24)));
        setHours(formatTime(Math.floor(totalSeconds / 3600) % 24));
        setMinutes(formatTime(Math.floor(totalSeconds / 60) % 60));
      } else {
        clearInterval(timerId);
      }
    }, 1000);
    return () => clearInterval(timerId);
  }, [inputDate]);

  // format time
  function formatTime(time) {
    return time;
  }

  return (
    <>
      {/* {formatTime(countDown)} Sec */}
      <Row className="my-3">
        <Col xs={1} md={0} lg={0}></Col>
        <Col xs={3} lg={3} className="p-0 m-0">
          <span className="inb-back">{days}</span>
        </Col>
        <Col xs={3} lg={3} className="p-0 m-0">
          <span className="inb-back">{hours}</span>
        </Col>
        <Col xs={3} lg={3} className="p-0 m-0">
          <span className="inb-back">{minutes}</span>
        </Col>
      </Row>
    </>
  );
};

export default CountDown;
