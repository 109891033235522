import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    <>
      <div className="loadingio-spinner-bean-eater-f5k2yix57mv">
        <div className="ldio-tecz3j4dcy">
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
