import React, { useEffect, useState } from "react";
import add from "../../assets/myTournaments/Timer_Add.png";

const CountDownHour = ({ inputDate }) => {
  // countdown
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      const changingDate = new Date(inputDate);
      const currentDate = new Date();
      const totalSeconds = (changingDate - currentDate) / 1000;
      if (totalSeconds > 0) {
        setDays(formatTime(Math.floor(totalSeconds / 3600 / 24)));
        setHours(formatTime(Math.floor(totalSeconds / 3600) % 24));
        setMinutes(formatTime(Math.floor(totalSeconds / 60) % 60));
      } else {
        clearInterval(timerId);
      }
    }, 1000);
    return () => clearInterval(timerId);
  }, [inputDate]);

  // format time
  function formatTime(time) {
    return time;
  }

  return (
    <div className="p-0 mt-3">
      <span>
        {days}:{hours}:{minutes}
      </span>
      <img className="mx-2" src={add} alt="time" />
    </div>
  );
};

export default CountDownHour;
