import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./voucherTabs.css";
import TextWithLineBreaks from "../../Pages/Voucher_game/TextWithLineBreaks";
import { useTranslation } from "react-i18next";

const VoucherTabs = ({ categoryData, categoryDataRedeem }) => {
  const { t } = useTranslation();
  return (
    <Tabs>
      <TabList>
        <Tab>{t("Description")}</Tab>
        <Tab>{t("How to Redeem")}</Tab>
        <Tab>{t("Terms And Conditions")}</Tab>
      </TabList>

      <TabPanel>
        <p>{categoryData.description_en || categoryData.description_ar}</p>
      </TabPanel>
      <TabPanel>
        <p>
          <TextWithLineBreaks text={categoryDataRedeem} />
        </p>
        {/* <p>{categoryData.redeemSteps_en}</p> */}
      </TabPanel>
      <TabPanel>
        <p>{t("Terms And Conditions")}</p>
        <p>
          <TextWithLineBreaks text={categoryData.TANDC_en || categoryData.TANDC_ar } />
        </p>
      </TabPanel>
    </Tabs>
  );
};

export default VoucherTabs;
