import React from "react";
import "./searchInput.css";
import { FaSearch } from "react-icons/fa";

const SearchInput = ({ queryInput, setQueryInput, searchPlaceholder }) => {
  return (
    <div className="searchBox">
      <FaSearch color="white" />
      <input
        className="searchInput"
        type="text"
        name="search"
        placeholder={searchPlaceholder}
        value={queryInput}
        onChange={(e) => {
          setQueryInput(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchInput;
