import React from "react";
import "./htmlGameItem.css";
import { useNavigate } from "react-router-dom";
import { notify1 } from "../ToastComponent";
import { useTranslation } from "react-i18next";

const Html5GameItem = ({ html_game }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const token = localStorage.getItem("token");
  return (
    <div className="htmlGame-item">
      <img
        src={html_game?.photo}
        alt="game_1"
        loading="lazy"
        onClick={() => navigate(`/htmlGameDetails/${html_game?.id}`)}
      />
      <div
        className="btn-join"
        onClick={() => {
          if (token && userId) {
            navigate(`/play/${html_game?.id}`);
          } else {
            notify1("Please Signin First");
          }
        }}
      >
        <span>{t("play now")}</span>
      </div>
    </div>
  );
};

export default Html5GameItem;
