import React, { useEffect, useState } from "react";
import bag_cart from "../../assets/cart.png";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Loading from "../../Components/Loading/Loading";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import "./store.css";
import SinglePayment from "../../Components/SinglePayment/SinglePayment";
import { ToastContainer } from "react-toastify";
import { notify, notify1 } from "../../Components/ToastComponent";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";
import { getStoreSubscription, postCartItem } from "../../Utilies/gamerClubApi";

const Store = () => {
  const { i18n } = useTranslation();
  const token = localStorage.getItem("token");
  // attay of subscribtion cards
  const { t } = useTranslation();
  const [arr, setArr] = useState([]);
  // show payment modal
  const [show, setShow] = useState(false);
  const [showId, setShowId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (idx) => {
    setShow(true);
    setShowId(idx);
  };

  // show side menu bar
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  // toggle tabs
  const [toggle, setToggle] = useState(1);
  const updateToggle = (id) => {
    setToggle(id);
  };

  // store cards lives & memberships
  const [userToken, setUserToken] = useState("");
  const [lives, setLives] = useState([]);
  const [memberships, setMemberships] = useState([]);
  // calling store data api and store it inside state
  async function getStore(langType) {
    try {
      const translations = await getStoreSubscription(1, langType);
      i18n.addResourceBundle(i18n.language, "translation", translations);
      setLives(translations?.items);
      const translations1 = await getStoreSubscription(2, langType);
      i18n.addResourceBundle(i18n.language, "translation", translations);
      setMemberships(translations1?.items);
    } catch (error) {}
  }

  // add to cart
  async function addToCart(sectionType, token, productId, cartItemType) {
    setArr([...arr, productId]);
    let formData = {
      items: [
        {
          object_id: productId,
          qty: 1,
          type: cartItemType,
        },
      ],
    };
    await postCartItem(formData, sectionType, token);
  }

  useEffect(() => {
    setUserToken(localStorage.getItem("token"));
    getStore(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Navbar title={t("store")} />
      <div className="store" style={{ paddingTop: "150px" }}>
        <Container fluid>
          <ToastContainer />
          <Row>
            <Col md={12}>
              <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={5} style={{ margin: "auto" }}>
              <div className="store-tabs">
                <span
                  onClick={() => updateToggle(1)}
                  className={toggle === 1 ? "active" : ""}
                >
                  {t("tokens")}
                </span>
                <span
                  onClick={() => updateToggle(2)}
                  className={toggle === 2 ? "active" : ""}
                >
                  {t("Memberships")}
                </span>
              </div>
            </Col>
          </Row>
          <div className="store-content">
            {/* lives cards */}
            <div className={toggle === 1 ? "show-cards lives" : "lives"}>
              <Row>
                {lives.length > 0 ? (
                  lives.map((live, index) => {
                    return (
                      <Col key={live.id} xs={12} md={6} lg={6} xl={3}>
                        <div className="gold-card">
                          <img
                            src={`http://api.gamerclub.live${live?.image}`}
                            alt="icon"
                          />
                          <div className="lives-price">
                            <span className="lives-number">{live?.name}</span>
                            <span className="lives-price">
                              {live?.price}
                              EGP
                            </span>
                          </div>
                          <div className="buy">
                            <div
                              className="add-to-cart"
                              onClick={() => {
                                if (userToken) {
                                  if (!arr.includes(live?.id)) {
                                    addToCart(
                                      "cart",
                                      token,
                                      live.id,
                                      "product"
                                    );
                                    notify("Your Item Added To Cart");
                                  } else {
                                    notify1("Already Exist In Cart");
                                  }
                                } else {
                                  notify1("Please Sign In First");
                                }
                              }}
                            >
                              <img src={bag_cart} alt="bag_cart" />
                              <span>{t("add to cart")}</span>
                            </div>
                            <button
                              onClick={() => {
                                handleShow(index);
                              }}
                              className="btn-buy"
                            >
                              {t("buy now")}
                            </button>
                            <Modal
                              show={showId === index && show}
                              onHide={handleClose}
                              keyboard={false}
                            >
                              <Modal.Body className="voucher-modal">
                                <Row>
                                  <Col lg={6}>
                                    <img
                                      className="w-100"
                                      src={live?.image}
                                      alt="lives"
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <p className="member-title">{live?.name}</p>
                                    <p className="mb-3 member-description">
                                      {live?.description}
                                    </p>
                                    <p className="mb-3 member-price">
                                      {live?.price}EGP
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="payment-tabs"
                                  >
                                    <SinglePayment live={live} qty={"1"} />
                                  </Col>
                                </Row>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Loading />
                )}
              </Row>
            </div>
            {/* membership cards */}
            <div
              className={
                toggle === 2 ? "show-cards cards-member" : "cards-member"
              }
            >
              <Row>
                <Col xs={0} md={0} lg={0} xl={3}></Col>
                {memberships.length > 0 ? (
                  memberships.map((member) => {
                    return (
                      <Col key={member.id} xs={12} md={6} lg={6} xl={3}>
                        <div className="gold-card">
                          <img
                            src={`http://api.gamerclub.live${member?.image}`}
                            alt=""
                          />
                          <div className="lives-price">
                            <span className="lives-number">{member?.name}</span>
                            <span
                              className="mb-3 lives-price"
                              style={{ flexDirection: "column", gap: "5px" }}
                            >
                              <div>
                                <span>{member.price}EGP</span>
                              </div>
                            </span>
                          </div>
                          <div className="buy">
                            <div
                              className="add-to-cart"
                              onClick={() => {
                                if (userToken) {
                                  if (!arr.includes(member.id)) {
                                    addToCart(
                                      "cart",
                                      token,
                                      member.id,
                                      "subscription"
                                    );
                                    notify("Your Item Added To Cart");
                                  } else {
                                    notify1("Already Exist In Cart");
                                  }
                                } else {
                                  notify1("Please Sign In First");
                                }
                              }}
                            >
                              <img src={bag_cart} alt="cart" />
                              <span>{t("add to cart")}</span>
                            </div>
                            <button
                              onClick={() => {
                                handleShow(member.id);
                              }}
                              className="btn-buy"
                            >
                              {t("subscribe now")}
                            </button>
                            <Modal
                              show={showId === member.id && show}
                              onHide={handleClose}
                              keyboard={false}
                            >
                              <Modal.Body className="voucher-modal">
                                <Row>
                                  <Col lg={6}>
                                    <img
                                      className="w-100"
                                      src={member?.image}
                                      alt="membership"
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <p className="member-title">
                                      {member.name}
                                    </p>
                                    <p className="member-description">
                                      {member?.description}
                                    </p>
                                    <p className="mb-3 member-price">
                                      {member.price}EGP
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="payment-tabs"
                                  >
                                    <SinglePayment live={member} qty={"1"} />
                                  </Col>
                                </Row>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Loading />
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Store;
