import React from "react";
import "./paymentSuccess.css";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosCheckmarkCircle } from "react-icons/io";
// import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  // const navigate = useNavigate();
  // const autoNavigate = () => {
  //   setTimeout(() => {
  //     navigate("/profile");
  //   }, 3000);
  // };

  return (
    <Container>
      <Row>
        <Col lg={3}></Col>
        <Col xs={12} lg={6}>
          <div className="pay-succ">
            <IoIosCheckmarkCircle />
            <h1>payment Success !</h1>
            <p className="mb-2 text-center">
              Thank you for completing your secure online payment.
            </p>
            <span>Have a great day!</span>
            <div className="submit-info"></div>
            {/* {autoNavigate()} */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
