import React from "react";
import "./htmlGames.css";
import Html5GameItem from "../Html5GameItem/Html5GameItem";
import Heading from "../Heading/Heading";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HtmlGames = ({ htmlGames }) => {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <div className="html-games home-html">
        <div className="battles-section">
          <Heading
            title={t("HTML 5 Games")}
            btn_title={t("see all")}
            headingNavigate={"/html5games"}
          />
          <hr />
          <div className="battles-games">
            <Row className="row">
              {htmlGames.length > 0 ? (
                htmlGames.map((htmlGame) => (
                  <Col xs={12} md={4} lg={3} key={htmlGame.id}>
                    <Html5GameItem html_game={htmlGame} />
                  </Col>
                ))
              ) : (
                <b>{t("no games found")}</b>
              )}
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HtmlGames;
