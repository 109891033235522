import React, { useEffect, useState } from "react";
import VoucherCard from "../VoucherCard/VoucherCard";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getUserVouchers, unsubscribe,  userLogout,
} from "../../Utilies/gamerClubApi";
import "./MySubscription.css"
import { notify1 } from "../ToastComponent";
import { useNavigate } from "react-router-dom";

import { notify } from "../ToastComponent";
import { ToastContainer } from "react-toastify";
import { Modal, Button } from 'react-bootstrap'; 

import LogoutConfirmationModal from "./UnsubscribeModal"; // Import the modal component


const Subscription = ({ toggle , userDetails }) => {

    const subscriptionDate = new Date(userDetails.subscription_date); // Convert the date string to a Date object

    const formattedDate = subscriptionDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  
  const { t } = useTranslation();
  const [userSub, setUserSub] = useState([]);
  // get user token
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  async function userSubs(token) {
    try {
        let result = await getUserVouchers(token);
      setUserSub(result.data);
    } catch (error) {}
  }
  const unsubscribeCall = async () => {
    try {


    await unsubscribe(token);
        notify("unsubscribed Successfully!");
        console.log(token)
        // handleConfirmLogout()
            try {
              await userLogout(token);
              localStorage.clear();
              navigate("/signIn", { replace: true });
            } catch (error) {
              console.log(error);
            }
          
        
     
    } catch (err) {
      notify1(err.response.data.error);
    }
  };


    const [showModal, setShowModal] = useState(false);
  
    // Function to handle showing the modal
    const handleLogoutClick = () => {
      setShowModal(true);
    };
    // Function to handle confirmation
    const handleConfirmLogout = () => {
        setShowModal(false);
        console.log('User confirmed logout');
        // Add your logout logic here
        unsubscribeCall()
      };
 // Function to handle closing the modal
 const handleCloseModal = () => {
    setShowModal(false);

    
  };    

  useEffect(() => {
    userSubs(token);
  }, [token]);

  return (
    <div className={toggle === 5? "show-content game-voucher" : "content"}>

<div className="list-content game-row show-content game-history">
      <ToastContainer />

      <Row className="my-3 wishlist">

      {userDetails.created_at && (

            <Col xs={12} md={12} lg={12} xxl={12} className="mb-3">
         <span>  {t("subscription date")}
            </span>  : { formattedDate}
            <br></br>
            <span>     {t("renewal date")} </span>: { userDetails?.renewal_date?? "-" } 
            <br></br>
        <span> {t("subscribed number")}</span>    :{ userDetails?.phone } 
        <br></br>

            <button
                    onClick={handleLogoutClick}    
            className="mb-4 shopping-check"
          >
            {t("unsubscribe")}
          </button>
            </Col>
      )}
            {!userDetails.created_at && (

          <Col xs={12} md={12} lg={12} className="mb-3">
            <h2 className="empty-cart">
              {t("No Subscription")}
            </h2>
          </Col>
            )}

<LogoutConfirmationModal
           show={showModal}
           handleClose={handleCloseModal}
           handleConfirm={handleConfirmLogout}
   
      />
      </Row>
    </div>
    </div>
  );
};

export default Subscription;
