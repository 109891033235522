import React from "react";
import "./androidGames.css";
import Heading from "../Heading/Heading";
import AndroidItem from "../AndroidItem/AndroidItem";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AndroidGames = ({ androidGames }) => {
  const { t } = useTranslation();
  return (
    <div className="android_games">
      <Container fluid>
        <div className="battles-section">
          <Heading
            title={t("android games")}
            btn_title={t("see all")}
            headingNavigate={"/android"}
          />
          <hr />
          <div className="battles-games">
            <Row>
              {androidGames &&
                androidGames.map((android) => (
                  <Col xs={6} md={3} lg={3} xl={2} key={android.id}>
                    <AndroidItem
                      android_img={android.thumb}
                      android_btn={t("download now")}
                      gameItemId={android.id}
                      imgNavigate={"androidGameDetails"}
                      btnNavigate={"androidGameDetails"}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AndroidGames;
