import React, { useEffect, useState } from "react";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import "./myTournaments.css";
import Modal4 from "../../Components/modals/modal4";
import { Col, Container, Row } from "react-bootstrap";
import { getMyTournaments } from "../../Utilies/api";
import Loading from "../../Components/Loading/Loading";
import Modal2 from "../../Components/modals/Modal2";
import CountDownHour from "../../Components/CountDownHour/CountDownHour";
import coin from "../../assets/coin.png";
import tourn_1 from "../../assets/myTournaments/tour_1.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/NavbarComp";

const MyTournamentsPage = () => {
  const navigate = useNavigate();
  // show modal1
  const [showModal4, setShowModal4] = useState(false);
  // show modal2
  const [showModal2, setShowModal2] = useState(false);

  // show side bar
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const token = localStorage.getItem("token");

  const [myTournaments, setMyTournaments] = useState([]);

  // get tournaments data
  async function getMyTournamentsItem(userId, token) {
    let result = await getMyTournaments(userId, token);
    setMyTournaments(result.data);
  }

  useEffect(() => {
    getMyTournamentsItem(userId, token);
  }, [userId, token]);

  return (
    <>
      <Navbar title={"my tournaments"} />
      <div className="myTournaments" style={{ paddingTop: "150px" }}>
        <Container>
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
        </Container>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="mt-5 tournament-inp">
                <Row>
                  <Col xs={0} lg={2}></Col>
                  <Col xs={12} md={6} lg={6}>
                    <button
                      className="mb-4 create-tourn"
                      onClick={() => setShowModal4(true)}
                    >
                      Create Tournament
                    </button>
                    <Modal4
                      showModal4={showModal4}
                      setShowModal4={setShowModal4}
                      setMyTournaments={setMyTournaments}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={2} className="col-sm-6 col-md-3">
                    <div
                      onClick={() => setShowModal2(true)}
                      className="tourn-btn"
                    >
                      or join
                    </div>
                    <Modal2
                      showModal2={showModal2}
                      setShowModal2={setShowModal2}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="container">
          <div className="row">
            <div className="mt-5 col-md-12">
              <h2 className="title">all tournaments</h2>
              <hr />
            </div>
          </div>
        </div>
        {/* Tournaments Section */}
        <div className="cards-member">
          <div className="container mt-3">
            <Row className="p-0">
              {myTournaments?.length > 0 ? (
                <div className="container mt-3">
                  <Row className="p-0">
                    {myTournaments?.length > 0 ? (
                      myTournaments.map((myTournament) => (
                        <Col xs={12} md={12} lg={6} key={myTournament.id}>
                          <div
                            className="tourn"
                            onClick={() => {
                              navigate(
                                `/battles-tournaments/tournaments/theWar/${myTournament.id}`
                              );
                            }}
                          >
                            <Row>
                              <Col xs={4} md={4} lg={4}>
                                <div className="tourn-img">
                                  <img
                                    src={
                                      myTournament?.logo
                                        ? myTournament?.logo
                                        : tourn_1
                                    }
                                    alt="tourn_img"
                                  />
                                </div>
                              </Col>
                              <Col xs={3} md={4} lg={4} className="p-0">
                                <div className="tourn-name">
                                  <p>{myTournament?.name}</p>
                                  <p>{myTournament?.genre}</p>
                                  <CountDownHour
                                    inputDate={myTournament.end_date_time}
                                  />
                                </div>
                              </Col>
                              <Col xs={5} md={4} lg={4}>
                                <div className="tourn-data">
                                  <div className="tourn-prize">
                                    <span>prize</span>
                                    <img src={coin} alt="" />
                                    <span>{myTournament?.prize}</span>
                                  </div>
                                  <div className="tourn-createdBy">
                                    Created by Playit
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {/* <div className="tourn-rank">your rank 1</div> */}
                          </div>
                        </Col>
                      ))
                    ) : (
                      <Loading />
                    )}
                  </Row>
                </div>
              ) : (
                <h2 className="empty-tourn">There Are No Tournaments</h2>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTournamentsPage;
