import React from "react";
import { Col, Row } from "react-bootstrap";

const PaymentHeading = ({
  title,
  btn_title,
  deleteCartItems,
  token,
  userId,
  newcart,
}) => {
  return (
    <Row className="mb-3 title">
      <Col xs={7} md={6} lg={6}>
        <h2>{title}</h2>
      </Col>
      {btn_title ? (
        <Col className="title-btn" xs={5} md={6} lg={6}>
          <button
            style={
              newcart.length === 0 ? { display: "none" } : { display: "block" }
            }
            onClick={() => deleteCartItems(token)}
            className="btn-more"
          >
            {btn_title}
          </button>
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export default PaymentHeading;
