import Modal from "react-bootstrap/Modal";
import modal_icon from "../../assets/modal_icon.png";
import "./modals.css";
import { Col, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { useTranslation } from "react-i18next";

function Modal1({ show, setShow, tournamentDetails }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title " className="w-100">
            <MdOutlineClose
              className="modal-close-btn"
              onClick={() => setShow(false)}
            />
            <Row>
         
              <Col className="text-center"xs={12} md={12} lg={12}>
                <img className="rules-icon" src={modal_icon} alt="icon" />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="mb-3">{t("What is Tournament Rules?")}</h2>
          <p>{tournamentDetails.rules}</p>
          <div className="text-rules mb-2">
            <h4>{t("rule1")}</h4>
            <ul>
              <li>{t("R1subRule1")}</li>
              <li>{t("R1subRule2")}</li>
              <li>{t("R1subRule3")}</li>
              <li>{t("R1subRule4")}</li>
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule2")}</h4>
            <ul>
              <li>{t("R2subRule1")}</li>
              <li>{t("R2subRule2")}</li>
              <li>{t("R2subRule3")}</li>
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule3")}</h4>
            <ul>
              <li>{t("R3subRule1")}</li>
              <li>{t("R3subRule2")}</li>
              <li>{t("R3subRule3")}</li>
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule4")}</h4>
            <ul>
              <li>{t("R4subRule1")}</li>
              <li>{t("R4subRule2")}</li>
              <li>{t("R4subRule3")}</li>
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule5")}</h4>
            <ul>
              <li>{t("R5subRule1")}</li>
              <li>{t("R5subRule2")}</li>
              <li>{t("R5subRule3")}</li>
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule6")}</h4>
            <ul>
              <li>{t("R6subRule1")}</li>
              <li>{t("R6subRule2")}</li>
            
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule7")}</h4>
            <ul>
              <li>{t("R7subRule1")}</li>
              <li>{t("R7subRule2")}</li>
             
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule8")}</h4>
            <ul>
              <li>{t("R8subRule1")}</li>
              <li>{t("R8subRule2")}</li>
            
           
            </ul>
          </div>
          <div className="text-rules mb-2">
            <h4>{t("rule9")}</h4>
            <ul>
              <li>{t("R9subRule1")}</li>
              <li>{t("R9subRule2")}</li>
              <li>{t("R9subRule3")}</li>
              <li>{t("R9subRule4")}</li>
           
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modal1;
