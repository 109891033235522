import React from "react";

const BattleItem = ({ tourn }) => {
  return (
    <div className="game-item">
      <img
        className="battle-home w-100"
        src={`http://api.gamerclub.live${tourn?.logo}`}
        alt="game_1"
        loading="lazy"
      />
      <span
        style={{ color: "white", fontSize: "18px", fontWeight: "bold" }}
        className="mb-0"
      >
        {tourn?.game?.name}
      </span>
    </div>
  );
};

export default BattleItem;
