import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ShoppingCart } from "lucide-react";
import { getCategoryDetails,purchaseVoucher } from "../../Utilies/api";
import { Card, Col, Modal, Row } from "react-bootstrap";
import SinglePayment from "../SinglePayment/SinglePayment";
import { notify, notify1 } from "../ToastComponent";
import { useTranslation } from "react-i18next";
import { postCartItem } from "../../Utilies/gamerClubApi";
import token from "../../assets/gamer_token.svg";
import RedeemVoucher from "../../Components/RedeemVoucher/RedeemVoucher.jsx";

const PayOrPlayItem = ({ category }) => {
  const { t } = useTranslation();
  // show payment modal
  const [index, setIndex] = useState(0);

  const [showId, setShowId] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    
      setShow(true);
   
  };
  // show confirm modal
  const [serialNumber, setSerialNumber] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const handleConfirmClose = () => setShowConfirm(false);
  const handleConfirmShow = () => {
    setShowConfirm(true);
  };
  const redeemVoucher = async (voucherId) => {
     handleConfirmClose();
    // get user token
    const token = localStorage.getItem("token");
    try {
      if (token) {
        let result = await purchaseVoucher(voucherId, token);
       
       
        console.log("result:", result);

        //show success masg
        
          handleShow();
          setSerialNumber(result[0].serialNumber)
          console.warn(result[0].serialNumber)
       
      } else {
        notify1("Please Enter Login First");
      }
    } catch (error) {
     
      if(error.response.data.code == 400){
        notify1("No Balance");
      }else{
        notify1(error.response.data.message);

      }
    }
  };
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const userToken = localStorage.getItem("token");
  // store category vouchers inside state
  const [categoryVouchers, setCategoryVouchers] = useState([]);
  // store select options
  const [selectOption, setSelectOption] = useState(0);
  const { i18n } = useTranslation();
  // show category vouchers in select dropdown menu in category card
  const getDetails = async (id, token, langType) => {
    let translations = await getCategoryDetails(id, token, langType);
    i18n.addResourceBundle(i18n.language, "translation", translations);
    setCategoryVouchers(translations.data.vouchers);
  };

  // show hidden category content
  const [showContent, setShowContent] = useState(false);
  const showCtegoryContent = () => {
    setShowContent(!showContent);
  };
  // convert price to integer number
  // const price = categoryVouchers[selectOption]?.sellPrice;
  const price = categoryVouchers[selectOption]?.token_price;
  
  let enprice = Intl.NumberFormat("ar-AR").format(price);

  const [arr, setArr] = useState([]);
  // add to cart
  async function addToCart(
    sectionType,
    token,
    productId,
    cartItemType,
    voucherQty
  ) {
    setArr([...arr, productId]);
    let formData = {
      items: [
        {
          object_id: productId,
          qty: voucherQty,
          type: cartItemType,
        },
      ],
    };
    await postCartItem(formData, sectionType, token);
  }
  function redeemToken(voucher , qty) {
    
  } 
  useEffect(() => {
    getDetails(category.id, userToken, i18n.language);
  }, [category.id, userToken, i18n.language]);

  return (
    <div className="gold-card">
      <div className="pull">
        <div className="pull-1"></div>
        <div className="pull-2"></div>
      </div>
      <img src={category.image} alt="category" />
      {categoryVouchers.length > 0 ? (
        <div className="vouch-details">
          <div className="lives-price">
            <Link to={`/voucher/${category.id}`} className="lives-number">
              {category.name_en || category.name_ar}
            </Link>
            {categoryVouchers.length <= 0 ? (
              <span className="voucher-price outofstock">Out Of Stock</span>
            ) : (
              <span className="voucher-price">
                 <img className="token_icon" src={token} alt="" />
                
                {enprice}
                {/* {category.voucher_currency} */}
              </span>
            )}
          </div>
          <p
            style={{
              overflowY: showContent ? "scroll" : "hidden",
              height: showContent ? "300px" : "300px",
            }}
          >
            {category.description_en || category.description_ar}
          </p>

          <div className="points">
            {category.description_en !== "" ? (
              <span onClick={() => showCtegoryContent()}>
                {!showContent ? t("Read More...") : t("Read Less...")}
              </span>
            ) : (
              ""
            )}
            {categoryVouchers.length > 0 ? (
              <select
                onChange={(e) => {
                  setSelectOption(e.target.value);
                  setIndex(e.target.value);
                }}
                className="form-select"
              >
                <option disabled={true}>Select Package</option>
                {categoryVouchers.map((cat, idx) => (
                  <option key={cat.id} value={idx}>
                    {cat.productName}
                  </option>
                ))}
              </select>
            ) : (
              ""
            )}
          </div>
          {categoryVouchers.length > 0 ? (
            <div className="buy d-flex justify-content-end">
              {/* <button
                className="btn-buy"
                onClick={() => {
                  if (userToken) {
                    if (
                      !arr.includes(categoryVouchers[index].id) &&
                      !categoryVouchers[index].has_cart
                    ) {
                      addToCart(
                        "cart",
                        userToken,
                        categoryVouchers[index].id,
                        "voucher",
                        1
                      );
                      // success alert message
                      notify("Added To Cart");
                    } else {
                      notify1("Already Exist In Cart");
                    }
                  } else {
                    notify1("Please Sign In First");
                  }
                }}
              >
                {t("add to cart")} <ShoppingCart />
              </button> */}
              <button
                onClick={() => {
                  handleConfirmShow();
                }}
                className="btn-buy "
              >
                {t("Redeem")}
              </button>
              <Modal
                      show={show}
                      onHide={handleClose}
                      keyboard={false}
                      className="buy-modal"
                      // size="lg"
                      // fullscreen={"md-down"}
                    >
                      <Modal.Body className="voucher-modal">
                        <Card>
                          {/* <Card.Img
                            variant="top"
                            className="h-auto "
                            src={categoryVouchers[index].productImage}
                          /> */}
                          <Card.Body>
                            <Card.Title className="my-3 text-white text-center">
                           {t(" Voucher redeemed successfully!")}
                             
                            </Card.Title>
                            <Row>
                              {/* <Col xs={12} md={6} lg={6}>
                                <img
                                  className="mb-3 w-100"
                                  style={{
                                    backgroundColor: "white",
                                    padding: "10px",
                                    borderRadius: "20px",
                                  }}
                                  src={categoryVouchers[index].productImage}
                                  alt="itemship"
                                />{" "}
                              </Col> */}
                              <Col xs={12} md={12} lg={12}>
                                {/*                               
                              <div className="d-flex justify-content-between">
                              <p className="price">
                                  <img
                                    className="token_icon"
                                    src={tokenIcon}
                                    alt=""
                                  />
                                  {categoryVouchers[index].token_price *
                                    inputValue}{" "}
                                  {categoryVouchers[index].productCurrency}
                                </p>
                                <div className="qty ">
                                  <p>
                                    <button
                                      style={
                                        inputValue <= 1
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      onClick={() =>
                                        decreaseAmount(
                                          categoryVouchers[index].id,
                                          "voucher",
                                          inputValue
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <span style={{ margin: "0 3px" }}>
                                      {inputValue}
                                    </span>
                                    <button
                                      style={
                                        inputValue >= 8
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "pointer" }
                                      }
                                      onClick={() =>
                                        increaseAmount(
                                          categoryVouchers[index].id,
                                          "voucher",
                                          inputValue
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </p>
                                </div>
                                
                                </div> */}
                                <RedeemVoucher
                                  category={categoryVouchers[index]}
                                  serialNumber={serialNumber}
                                ></RedeemVoucher>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        {/* <SinglePayment
                          live={categoryVouchers[index]}
                          qty={inputValue}
                        /> */}
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={showConfirm}
                      onHide={handleConfirmClose}
                      keyboard={false}
                      className="confirm-modal"
                      centered
                      size="lg"
                    >
                      <Modal.Body className="confirm-modal">
                        <Card>
                          <Card.Body>
                            <Card.Title className="my-3 text-white">
                              {/* {categoryVouchers[index].productName} */}
                            </Card.Title>
                            <Row>
                              <Col xs={12} md={12} lg={12}>
                                <p className="">
                                  Are you sure you want to redeem{" "}
                                  {categoryVouchers[index].productName} for{" "}
                                  {categoryVouchers[index].token_price} ?
                                </p>
                              </Col>
                              <Col xs={12} md={12} lg={12}>
                                <div className="d-flex justify-content-center gap-2">
                                  <button
                                    onClick={() => {
                                      redeemVoucher(categoryVouchers[index].id);
                                    }}
                                    className="btn  confirm-btn"
                                  >
                                    {t("Confirm")}
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleConfirmClose();
                                    }}
                                    className="btn cancel-btn"
                                  >
                                    {t("Cancel")}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                    </Modal>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PayOrPlayItem;
