import Modal from "react-bootstrap/Modal";
import coin from "../../assets/gamer_token.svg";
import modal_icon from "../../assets/modal_icon.png";
import "./modals.css";
import { Col, Row } from "react-bootstrap";
import CountDown from "../CountDown/CountDown";
import { MdOutlineClose } from "react-icons/md";
import { t } from "i18next";

const Modal3 = ({ showModal, setShowModal, tournamentDetails }) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <MdOutlineClose
              className="modal-close-btn"
              onClick={() => setShowModal(false)}
            />
            <Row>
              <Col xs={3} md={3} lg={3}></Col>
              <Col xs={6} md={6} lg={6}>
                <img className="w-100" src={modal_icon} alt="icon" />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={12} lg={12} className="m-auto">
              <div className="mb-3 modal-btns">
                {/* countdown */}
                <CountDown inputDate={tournamentDetails?.end_date} />
              </div>
              <Row className="m-auto">
                <Col lg={12}>
                  <p className="mt-3 text-center top-rank">{t("Top Rank")}</p>

                  {tournamentDetails?.prizes.map((prize) => {
                    return (
                      <Row>
                        <Col xs={6} md={6} lg={6}>
                          <h5>{t("Rank")}</h5>
                          <p>{prize.rank}</p>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                          <h5>{t("prize")}</h5>
                          <span>{prize.prize}</span>{" "}
                          <span>{prize.currency}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              {/* <div className="coin-btn">
                <span>{t("Join For")}</span>
                <img src={coin} alt="coins" />
                <span>{tournamentDetails?.entry_fee}</span>
              </div> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modal3;
