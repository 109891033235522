import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import HtmlGames from "../../Components/Html5Games/HtmlGames";
import AndroidGames from "../../Components/AndroidGames/AndroidGames";
import Info from "../../Components/info/Info";
import GamesSlider from "../../Components/GamesSlider/GamesSlider";
import Navbar from "../../Components/Navbar/NavbarComp";
import {
  getHomeGamesApi,
  getHomeSubscription,
  getHomeTournamnets,
  getHomeVouchers,
} from "../../Utilies/gamerClubApi";
import HomeTournaments from "../../Components/HomeTournaments/HomeTournaments";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { i18n } = useTranslation();
  // const [battles, setBattles] = useState([]);
  const [tourns, setTourns] = useState([]);
  const [androidGames, setAndroidGames] = useState([]);
  const [htmlGames, setHtmlGames] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [packages, setPackages] = useState([]);

  // get home pay or play vouchers
  const getHomeVouchersApi = async (langType) => {
    try {
      let translations = await getHomeVouchers(langType);
      i18n.addResourceBundle(i18n.language, "translation", translations);

      setVouchers(translations.data);
    } catch (error) {}
  };
  // get home games
  const HomeTournamentsApi = async () => {
    try {
      const reponse = await getHomeTournamnets();
      setTourns(reponse);
    } catch (error) {}
  };
  // get home Subscription
  const getHomeSubscriptionApi = async (langType) => {
    try {
      const translations = await getHomeSubscription(langType);
      i18n.addResourceBundle(i18n.language, "translation", translations);
      setPackages(translations);
    } catch (error) {}
  };

  const getHomeGames = async () => {
    try {
      const response = await getHomeGamesApi();
      setAndroidGames(response.android);
      setHtmlGames(response.html);
    } catch (error) {}
  };
  const [showAndroidGames, setShowAndroidGames] = useState(true);

  useEffect(() => {
    const hostname = document.location.host;
    if (hostname == "etuae.gamerclub.live") {
      localStorage.setItem("i18nextLng", "en");
    }

    if (hostname === 'etuae.gamerclub.live') {
      setShowAndroidGames(false);
    }
    getHomeVouchersApi(i18n.language);
    HomeTournamentsApi();
    getHomeSubscriptionApi(i18n.language);
    getHomeGames();
  }, [i18n.language]);

  return (
    <>
      <Navbar />
      <Header />

      <GamesSlider vouchers={vouchers} lang={i18n.language} />
      {/* <Battles battles={battles} /> */}
      <HomeTournaments tourns={tourns} />
      <HtmlGames htmlGames={htmlGames} />
      {/* <Info packages={packages} /> */}
      {showAndroidGames && <AndroidGames androidGames={androidGames} />}

      {/* <AndroidGames androidGames={androidGames} /> */}
    </>
  );
};

export default Home;
