import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./OtpCode.css";

const languages = [
  { code: "en", name: "EN" },
  { code: "ar", name: "AR" },
];
const AuthLanguageSelector = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <div className="">
      <select
        onChange={(e) => changeLanguage(e.target.value)}
        defaultValue={i18next.language}
        // className="language-selector"
      >
        {languages.map((language, index) => (
          <option
            key={index}
            // selected={language.code === i18n.language ? true : false}
            value={language.code}
          >
            {language.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AuthLanguageSelector;
