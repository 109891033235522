import Modal from "react-bootstrap/Modal";
import coin from "../../assets/coin.png";
import modal_icon from "../../assets/modal_icon.png";
import "./modals.css";
import { useState } from "react";
import { checkTournamentCode } from "../../Utilies/api";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { notify, notify1 } from "../ToastComponent";

const Modal2 = ({ showModal2, setShowModal2 }) => {
  const navigate = useNavigate();
  // store user input code
  const [code, setCode] = useState("");
  // alert message
  const [tournDetails, setTournDetails] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const token = localStorage.getItem("token");
  // function to check enterd code
  async function checkCode(code, userId, token) {
    let formData = {
      code: code,
    };

    let { data, message } = await checkTournamentCode(formData, userId, token);
    if (message === "there is no tournament have this code") {
      notify1("No Tournament Have This code");
      setAlertMsg(message);
    } else {
      notify(message);
      setAlertMsg(message);
    }
    setTournDetails(data.contest);
  }

  return (
    <>
      <Modal
        show={showModal2}
        onHide={() => setShowModal2(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <ToastContainer />
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <MdOutlineClose
              className="modal-close-btn"
              onClick={() => setShowModal2(false)}
            />
            <Row>
              <Col xs={3} md={3} lg={3}></Col>
              <Col xs={6} md={6} lg={6}>
                <img className="w-100" src={modal_icon} alt="icon" />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="m-auto col-md-8">
              <h2 className="my-3 mb-3">Warriors</h2>
              <div className="mb-3 modal-btns">
                <input
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  className="mb-3 code-inp"
                  type="text"
                  placeholder="Enter Code"
                />
              </div>
              {/* check tournament code */}
              {alertMsg === "you are already joined" ? (
                <div
                  onClick={() => {
                    navigate(
                      `/battles-tournaments/tournaments/theWar/${tournDetails?.id}`
                    );
                  }}
                  className="mb-5 coin-btn"
                >
                  <span>join</span>
                  <img src={coin} alt="coins" />
                  <span>{tournDetails?.entryfee_deposit}</span>
                </div>
              ) : (
                <div
                  onClick={() => checkCode(code, userId, token)}
                  className="mb-5 coin-btn"
                >
                  <span>check code</span>
                </div>
              )}

              {/* join tournament */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modal2;
