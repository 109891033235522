import React from "react";
import "./tournaments.css";
import BattleItem from "../BattleItem/BattleItem";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import coin from "../../assets/gamer_token.svg";
import { useTranslation } from "react-i18next";

const HomeTournaments = ({ tourns }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="mb-5 tournaments">
      <Container fluid>
        <div className="tournaments-section">
          <Row>
            <Col lg={12}>
              <h1 className="section-title gradient-text">
                {t("Tournaments")}
              </h1>
            </Col>
          </Row>
          <div className="tournaments-games">
            <Row>
              {tourns &&
                tourns.map((tourn) => (
                  <Col xs={12} md={3} lg={3} className="p-0" key={tourn.id}>
                    <BattleItem tourn={tourn} />
                    <div
                      className="btn-join"
                      onClick={() =>
                        navigate(
                          `/battles-tournaments/tournaments/theWar/${tourn.id}`
                        )
                      }
                    >
                      {tourn.is_joined === true ? (
                        <span>{t("play now")}</span>
                      ) : (
                        <>
                          <span>{t("join")}</span>
                          <img src={coin} alt="coin name" />
                          <span>{tourn.entry_fee}</span>
                        </>
                      )}
                    </div>
                  </Col>
                ))}
              <Col xs={12} md={12} lg={12} className="p-0 ">
                <button
                  className="mt-5 seeAll-btn"
                  onClick={() => navigate("/battles-tournaments/tournamnets")}
                >
                  <p style={{ color: "white" }}>{t("see all")}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill=""
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={4}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeTournaments;
