import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Using Bootstrap for simplicity
import { useTranslation } from "react-i18next";

const LogoutConfirmationModal = ({ show, handleClose, handleConfirm }) => {
    const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title> {t("confirm subscription")}</Modal.Title>
      </Modal.Header>
     
      <Modal.Body>{t("Are you sure you want to unsubscribe?")}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        {t("cancel")}
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
        {t("unsubscribe")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutConfirmationModal;
