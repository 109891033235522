import React, { useState } from "react";
import Footer from "../Components/Footer/Footer";
import SideMenuBar from "../Components/SideMenuBar/SideMenuBar";
import { NavLink, Outlet } from "react-router-dom";
import ScrollToTop from "../Components/ScrollTop";
import Navbar from "../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";

const BattlesAndTournsLayOut = () => {
  const { t } = useTranslation();
  // toggle navigation bar
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  return (
    <>
      <ScrollToTop />
      <Navbar title={t("Tournaments")} />
      <div className="battles" style={{ paddingTop: "150px" }}>
        <div className="container">
          <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
          {/* <div className="row">
            <div className="col-md-8 col-lg-4" style={{ margin: "auto" }}>
              <div className="store-tabs">
                <NavLink to="battles-tournaments/battles">battles</NavLink>
                <NavLink to="battles-tournaments/tournamnets">
                  tournaments
                </NavLink>
              </div>
            </div>
          </div> */}
          <div className="store-content">
            <Outlet />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BattlesAndTournsLayOut;
