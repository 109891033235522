import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import "./userFormData.css";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserDetails, updateUserData } from "../../Utilies/gamerClubApi";
import avatar from "../../assets/person.png";
import { TbPhotoEdit } from "react-icons/tb";
import { notify } from "../ToastComponent";
import { useNavigate } from "react-router-dom";

const UserFormData = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inputref = useRef(null);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const extractDate = (datetimeStr) => {
    const date = new Date(datetimeStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  // const [isDisabled, setIsDisabled] = useState(false);
  const [photo, setPhoto] = useState(null); // Store file object
  const [fName, setFName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        avatar: photo,
        first_name: fName,
        dob: dob,
        gender: gender,
      };
      if (typeof photo === "string") {
        delete formData.avatar;
      }
      await updateUserData(formData, token);
      notify("Profile Updated Successfully");
      setTimeout(() => {
        navigate("/profile");
      }, 1500);
    } catch (error) {
      // setErrMsg(error.response.data.errors);
    }
  };
  const getUserData = async (token) => {
    try {
      const result = await getUserDetails(token);
      setPhoto(result.avatar);
      setFName(result.first_name);
      setDob(result.dob);
      setGender(result.gender);
      setEmail(result.email);
    } catch (error) {}
  };
  useEffect(() => {
    if (token) {
      getUserData(token);
    }
  }, [isLoading, token]);
  const handleImgClick = () => {
    inputref?.current?.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setPhoto(file);
  };

  return (
    <>
      <div className="profile-img">
        {photo ? (
          <img
            src={
              typeof photo == "string"
                ? `http://api.gamerclub.live${photo}`
                : URL.createObjectURL(photo)
            }
            alt="avatar"
            className="img"
          />
        ) : (
          <img src={avatar} alt="avatar" />
        )}
        {errMsg.avatar && (
          <div className="mt-2 text-danger">
            {errMsg.avatar.map((error) => (
              <p className="mb-2" key={error}>
                {error}
              </p>
            ))}
          </div>
        )}
        <div className="img-edit">
          <TbPhotoEdit
            style={{ fontSize: "35px", cursor: "pointer" }}
            onClick={handleImgClick}
            ref={inputref}
          />
        </div>
        {/* Hidden file input */}
        <input
          ref={inputref}
          onChange={(e) => {
            handleImageChange(e);
          }}
          type="file"
          accept="image/*"
          id="fileInput"
          style={{ display: "none" }}
        />
      </div>
      <form className="user-form" onSubmit={handleSubmit}>
        <Row className="mb-2">
          <ToastContainer />
          <Col xs={12} lg={12} className="mb-3">
            <Form.Label>{t("Name")}</Form.Label>
            <Form.Control
              value={fName}
              name="name"
              onChange={(e) => setFName(e.target.value)}
              placeholder="Name"
              required={true}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} lg={12}>
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required={true}
            />
            {errMsg ? (
              <div className="mt-3 mb-0 alert alert-danger">{errMsg}</div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} md={6} lg={6} className="mb-3">
            <Form.Label>{t("Gender")}</Form.Label>
            <select
              className="gender-select"
              defaultValue="Select Gender"
              onChange={(e) => setGender(e.target.value)}
              name="gender"
            >
              <option value={1} selected={gender === 1 ? "selected" : ""}>
                {t("Male")}
              </option>
              <option value={2} selected={gender === 2 ? "selected" : ""}>
                {t("Female")}
              </option>
            </select>
          </Col>
          <Col xs={12} md={6} lg={6} className="">
            <Form.Label>{t("Birth Of Date")}</Form.Label>
            <Form.Control
              value={extractDate(dob)}
              name="birthdate"
              onChange={(e) => setDob(e.target.value)}
              type="date"
              required={true}
            />
          </Col>
          <Col xs={12} md={8} lg={6} className="m-auto mt-4">
            <button type="submit" className="voucher-btn">
              {loading ? (
                t("Save changes")
              ) : (
                <i className="fas fa-spinner fa-spin"></i>
              )}
            </button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default UserFormData;
