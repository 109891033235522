import React, { useState } from "react";
import Footer from "../Components/Footer/Footer";
import { Container } from "react-bootstrap";
import SideMenuBar from "../Components/SideMenuBar/SideMenuBar";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../Components/ScrollTop";

const PaymentLayOut = () => {
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  return (
    <>
      <ScrollToTop />
      <Container>
        <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
      </Container>
      <Outlet />
      <Footer />
    </>
  );
};

export default PaymentLayOut;
