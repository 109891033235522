import React, { useState } from "react";
import "./successMsg.css";
import rightCheck from "../../assets/forms/Group.png";
import { useNavigate } from "react-router-dom";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";

const SuccessMsg = () => {
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <Container style={{ paddingTop: "150px", paddingBottom: "50px" }}>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
          </Col>
        </Row>
        <Row>
          <Col
            xs={11}
            md={10}
            lg={6}
            className="m-auto my-5 col-md-10 col-lg-6 rate-section"
          >
            <h1 className="mt-5 mb-3">thanks for rating us</h1>
            <p className="text-center">Lorem Ipsum is simply dummy text</p>
            <div className="check">
              <img src={rightCheck} alt="check" />
            </div>
            <div className="mb-5 submit-info">
              <button
                className="mb-3 play-button btn-submit"
                onClick={() => navigate("/")}
              >
                Go Home Page
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SuccessMsg;
