import React from "react";
import coin from "../../assets/gamer_token.svg";
import { useNavigate } from "react-router-dom";
import CountDownHour from "../CountDownHour/CountDownHour";
import { Col, Row } from "react-bootstrap";
import tourn_1 from "../../assets/myTournaments/tour_1.png";
import { useTranslation } from "react-i18next";

const TournItem = ({ tournament }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="tourn"
      onClick={() => {
        navigate(`/battles-tournaments/tournaments/theWar/${tournament.id}`);
      }}
    >
      <Row>
        <Col xs={4} md={4} lg={4}>
          <div className="tourn-img">
            <img
              src={`http://api.gamerclub.live${tournament?.logo}`}
              alt="tourn_img"
            />
          </div>
        </Col>
        <Col xs={3} md={4} lg={4} className="p-0">
          <div className="tourn-name">
            <p>{tournament?.name}</p>
            <CountDownHour inputDate={tournament?.end_date} />
          </div>
        </Col>
        <Col xs={5} md={4} lg={4}>
          <div className="tourn-data">
            <div className="tourn-prize">
              <span>{t("prize")}</span>
              <img src={coin} alt="coin" />
              <span>{tournament?.total_prize}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TournItem;
