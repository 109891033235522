import i18next from "i18next";
import languagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Import translations
import translationEN from './locales/en.json';
import translationAR from './locales/ar.json';

i18next.use(languagedetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR }
    },
    interpolation: {
      escapeValue: false // not needed for React as it escapes by default
    }
})