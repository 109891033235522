import React, { useState } from "react";
import "./settings.css";
import edit from "../../assets/settings/aside/Edit.png";
import notify from "../../assets/settings/aside/Notifications.png";
import help from "../../assets/settings/aside/Help.png";
import { Col, Container, Form, Row } from "react-bootstrap";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import UserFormData from "../../Components/UserFormData/UserFormData";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(1);
  const updateToggle = (id) => {
    setToggle(id);
  };
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  return (
    <>
      <Navbar title={t("settings")} />
      <div className="settings" style={{ paddingTop: "150px" }}>
        <Container>
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
          <div className="setting-content">
            {/* aside */}
            <Row>
              <Col xs={12} md={4} lg={4}>
                <div className="setting-side">
                  <div
                    onClick={() => updateToggle(1)}
                    className={
                      toggle === 1 ? "setting-icon activ" : "setting-icon"
                    }
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img src={edit} alt="" />
                      </div>
                      <div className="col-md-10">
                        <span>{t("Edit profile")}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => updateToggle(2)}
                    className={
                      toggle === 2 ? "setting-icon activ" : "setting-icon"
                    }
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img src={notify} alt="" />
                      </div>
                      <div className="col-md-10">
                        <span>{t("Notifications")}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => updateToggle(3)}
                    className={
                      toggle === 3 ? "setting-icon activ" : "setting-icon"
                    }
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <img src={help} alt="" />
                      </div>
                      <div className="col-md-10">
                        <span>{t("terms and conditions")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/* settings button */}

              <Col xs={12} md={8} lg={8}>
                {/* user edit form */}
                <div
                  className={
                    toggle === 1
                      ? "show-content user-edit mb-5 text-center"
                      : "hide-content"
                  }
                >
                  {/* user edit form */}
                  <UserFormData />
                </div>
                {/* user edit form */}

                {/* notification */}
                <div
                  className={
                    toggle === 2
                      ? "show-content user-edit mb-5"
                      : "hide-content"
                  }
                >
                  <div className="notification">
                    <span>{t("Notifications")}</span>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                </div>
                {/* notification */}

                {/* licence */}
                <div
                  className={
                    toggle === 3
                      ? "show-content user-edit mb-5"
                      : "hide-content"
                  }
                >
                  <p className="mb-3 terms">{t("terms1")}</p>
                  <p className="mb-3 terms">{t("terms2")}</p>
                  <p className="mb-3 terms"> {t("terms3")}</p>
                  <p className="mb-3 terms"> {t("terms4")}</p>
                  <p className="mb-3 terms"> {t("terms5")}</p>
                  <p className="mb-3 terms"> {t("terms6")}</p>
                  <p className="mb-3 terms"> {t("terms7")}</p>
                  <p className="mb-3 terms"> {t("terms8")}</p>
                  <p className="mb-3 terms"> {t("terms9")}</p>
                  <p className="mb-3 terms"> {t("terms10")}</p>
                  <p className="mb-3 terms"> {t("terms11")}</p>
                  <p className="mb-3 terms"> {t("terms12")}</p>
                  <p className="mb-3 terms"> {t("terms13")}</p>
                  <p className="mb-3 terms"> {t("termsContact Us")}</p>

                  <div className="license">
                    <Form.Check aria-label="option 1" checked />
                    <span>{t("agree with terms and conditions")}</span>
                  </div>
                </div>
                {/* licence */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Settings;
