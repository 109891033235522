import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BattleItem from "../BattleItem/BattleItem";
import Loading from "../Loading/Loading";
import { checkTournamentJoin, getBattles } from "../../Utilies/api";
import coin from "../../assets/coin.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notify, notify1 } from "../ToastComponent";

const BattlesComponent = () => {
  const navigate = useNavigate();
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const token = localStorage.getItem("token");

  const [joinResult, setJoinResult] = useState([]);

  const [battles, setBattles] = useState([]);
  // const [page, setPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(0);
  // const [lodaing, setLoading] = useState(false);

  // get battles data
  async function getBattlesItem(userId, token) {
    // setLoading(true);
    let result = await getBattles(userId, token);
    setBattles(result.data);
    // setBattles((pre) => [...pre, ...result.data.battles]);
    // setTotalPage(result.data.pagination.hasNextPage);
    // if (result.message === "success") {
    //   setLoading(false);
    // }
  }

  // check battle join
  async function JoinTournament(userId, tournId, token) {
    let formData = {
      player_id: userId,
      contest_id: tournId,
    };
    try {
      let result = await checkTournamentJoin(formData, token);
      setJoinResult(result.data);
      notify(result.message);
    } catch (error) {
      notify1(error.data.message);
    }
  }

  useEffect(() => {
    getBattlesItem(userId, token);
  }, [userId, token]);

  return (
    <div>
      <ToastContainer />
      <Row>
        {battles.length > 0 ? (
          battles.map((battle) => (
            <Col xs={6} md={3} lg={3} key={battle.contests_id} className="mb-5">
              <BattleItem img={battle.logo} price={battle.entryfee_deposit} />
              <div
                className="btn-join"
                onClick={() => {
                  JoinTournament(userId, battle.id, token);
                  if (joinResult) {
                    setTimeout(() => {
                      navigate(
                        `/battles-tournaments/battles/theWar/${battle.id}/${battle.webGame.id}`
                      );
                    }, 3500);
                  }
                }}
              >
                <span>join</span>
                <img src={coin} alt="coin name" />
                <span>{battle.entryfee_deposit}</span>
              </div>
            </Col>
          ))
        ) : (
          <Loading />
        )}
      </Row>
      {/* <div className="row text-center">
        <div className="col-lg-5"></div>
        <div className="col-lg-2">
          <div
            className="btn-join"
            style={!totalPage ? { display: "none" } : { display: "block" }}
            onClick={() => {
              if (totalPage === true) {
                setPage(page + 1);
              } else {
                setLoading(false);
              }
            }}
          >
            {lodaing ? <i className="fas fa-spinner fa-spin"></i> : "load more"}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BattlesComponent;
