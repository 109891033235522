import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getGameDetails } from "../../Utilies/api";
import full_icon from "../../assets/fullscreen_icon.png";
import "./IframeGame.css";

const IframeGame = () => {
  // get game id
  let param = useParams();
  console.warn(param);
  // get user token
  const token = localStorage.getItem("token");
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  let gameId = param.id || param.gameId;
  let contestId = param.tournId;
  const objectRef = useRef(null);

  const [gameDetails, setGameDetails] = useState("");
  async function gameItemDetails(gameId, token) {
    try {
      let result = await getGameDetails(gameId, token);
      console.log(result, "before result");
 
      function secureFolderUrl(data) {
        if (data.folder.startsWith("http://")) {
          data.folder = data.folder.replace("http://", "https://");
        }
        return data;
      }
      let secureUrlRresult = secureFolderUrl(result);
      setGameDetails(secureUrlRresult);

      console.log(secureUrlRresult, "after result");
    } catch (error) {
      console.log(error);
    }

  }
  // full screen
  // const [goFull, setGoFull] = useState(false);
  useEffect(() => {
    gameItemDetails(gameId, token);
    // let element = document.getElementById("my-fullScreen");
 
    // if (element.requestFullscreen) {
    //   element.requestFullscreen();
    // } else if (element.webkitRequestFullscreen) { // For iOS Safari
    //   element.webkitRequestFullscreen();
    // } else {
    //   console.log("Fullscreen API is not supported.");
    // }
    if (objectRef.current) {
      if (objectRef.current.requestFullscreen) {
        objectRef.current.requestFullscreen();
      } else if (objectRef.current.mozRequestFullScreen) { // Firefox
        objectRef.current.mozRequestFullScreen();
      } else if (objectRef.current.webkitRequestFullscreen) { // Chrome, Safari, Opera
        objectRef.current.webkitRequestFullscreen();
      } else if (objectRef.current.msRequestFullscreen) { // IE/Edge
        objectRef.current.msRequestFullscreen();
      }
    }
   
  }, [gameId, token]);

  return (
    <div className="iframe-container">
      {/* <button
        id="btn"
        className="full"
        onClick={() => {
          setGoFull(!goFull);
        }}
      >
        <img src={full_icon} alt="full-icon" />
      </button> */}
      <div className="vh-100" id="my-fullScreen" ref={objectRef}>
        {" "}
        {gameDetails.folder ? (
          <object
            type="text/html"
            data={
              gameDetails.folder +
              "?user_id=" +
              "6yDUoQCKCk"// userId
               +
              "&game_id=" +
              gameDetails.id  +
              "&game_text=" +
              gameDetails.game_id 
              +
              "&contest_id=" +
              contestId
              +
              "&token=" +
              token
            }
            scrolling="no"
            tabIndex="0"
            id="objectRef"
            allowFullScreen
            sandbox="allow-same-origin allow-scripts allow-fullscreen"
          ></object>
        ) : null}
      </div>
    </div>
  );
};

export default IframeGame;
