import React, { useState , useEffect } from "react";
// import axios from "axios";
import "./OtpCode.css";
import OTPInput from "react-otp-input";
import otp_side from "../../assets/forms/side_otp_img.png";
import side_img from "../../assets/forms/side_signup_img.png";
import { Link, useNavigate } from "react-router-dom";
import { saveTracker,setAgencyData } from "../../Utilies/gamerClubApi";
import { Col, Row } from "react-bootstrap";
import FormsAside from "../../Components/FormsAside/FormsAside";
import { notify } from "../../Components/ToastComponent";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { axios } from "../../api/axiosInterceptor";

const SignUp = () => {
  localStorage.setItem('i18nextLng','ar');
  const { t } = useTranslation();
  const navigate = useNavigate();
  // lodaing state
  const [lodaing, setLodaing] = useState(true);
  // errors messages state
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [transactionidentify,setTransactionidentify] = useState("");
  const [transactionid,setTransactionid] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [showSub, setshowSub] = useState(false);
  const [showPendding, setshowPendding] = useState(true);
  const [phonenumber , setphonenumber] = useState("");
  const [sub_id , setsub_id] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [errorresverify, seterrorresverify] = useState("");
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);
  const [showverif, setShowverif] = useState(false);

  const [agree, setagree] = useState(false);

  // user state
  const [formData, setFormData] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
  });


  useEffect(() => {
    localStorage.setItem('i18nextLng','ar');

    //yousry lang
   //console.log("yousry lang");
    MCPShield();
    const params = queryString.parse(window.location.search);
    if (params.lang) {
     //console.log("lang",params.lang);
      localStorage.setItem("i18nextLng", params.lang);
      if(localStorage.getItem("i18nextLng") != params.lang){
        localStorage.setItem("i18nextLng", params.lang);
        window.location.reload();
      }
      //window.location.reload();
      //localLang.checklocalLanguage();
    }
    setAgencyData(params);
     if(params.camp){
       setagree(true)
      }
    if (params.msisdn == undefined) {
      setshowSub(false);
      
     getRedirectURL(params);
     console.log(params);
    
   }
   else{
   
   var phone = params.msisdn;
   if(phone !== null &&  phone !== ""){
   fetchDataWithMsidn(phone);
   }else{
  setshowPendding(false);
   }
   
   }


    
  }, []);


  async function getRedirectURL(params){
    //setLodaing(true);
    //showPendding(true);
    var url = "http://api.gamerclub.live/api/trendy-algeria/getheredirecturl";

    if(params.trafficsource ){
      url = "http://api.gamerclub.live/api/trendy-algeria/getheredirecturl?trafficsource="+params.trafficsource+"&parameter="+params.parameter;

    }
    if (params.camp ){
      url = "http://api.gamerclub.live/api/trendy-algeria/getheredirecturl?camp="+params.camp;
       setagree(true);
    }
    await axios.get(url)
    .then(response => {
    
       const url = response.data.redirect_url;
       if(url !=="" && url != null){
         
           window.location.href=url;
       }
      
      
    })
    .catch(error=> {
      console.log(error);
      
    })
    
  }

  const fetchDataWithMsidn = async (phone) => {
    try {
      const response = await axios.get(`http://api.gamerclub.live/api/trendy-algeria/gethemsisdn?decry_msisdn=${phone}`);
       const phone_number= response.data.msisdn;
       //console.log("phone_number ",phone_number);
       const subId =response.data.sub_id;
       if(phone_number){
        setphonenumber(phone_number);
        setActiveData(phone_number);
        setCheckPhone(true);
        setsub_id(subId);
        setshowSub(true);
        setshowPendding(false);
       }
       
       if(phone_number){
                  setshowSub(true);
                  setshowPendding(false);
      }
      
       
    } catch (error) {
      console.error('Error  msidn:', error);
    }
  };

  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem('tracker')) {
      var op_id = 'zFGLFvkj8L';
      datag = {
        sendCamp: 1,
        company: localStorage.getItem("trackerCompany"),
        key: localStorage.getItem('tracker'),
        operator_id: 3,
        msisdn: GMSISDN
      };
     //console.log(datag)  
    }
    await saveTracker(datag);

  }


  function manipulateMsisdn(msisdn) {
    msisdn = msisdn.replace('+', '');
    if (startsWith(msisdn, '0')) {
        msisdn = msisdn.substring(1);
    } else if (startsWith(msisdn, '00')) {
        msisdn = msisdn.substring(2);
    }
    if (!startsWith(msisdn, '213')) {
        msisdn = '213' + msisdn;
    }
    return msisdn.trim();
}

function startsWith(str, prefix) {
    return str.indexOf(prefix) === 0;
}


  async function MCPShield(){
    //e.preventDefault();
   //console.log("header Subscribe");
    var url = `http://api.gamerclub.live/api/trendy-algeria/get_script?element=signupbut`;
    //setLodaing(true);
    axios.get(url)
      .then(res => {
       console.log(res);
       console.log(res.data.status);
        if (res.data.status == true) {
          const addScript = document.createElement('script');
          
          addScript.innerHTML = res.data.script;
          document.head.appendChild(addScript);
          setTransactionidentify(res.data.transactionId)
          setTransactionid(res.data.ti)
          setLodaing(false);
          var event = new Event('DCBProtectRun');
          document.dispatchEvent(event);
        }
        else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message)
          // setshowagin(true)
        }

        setLodaing(true);

      })
      .catch(function (error) {
        // setErrorMesg(true)
      })
      .finally(function () {
       //console.log('complete');
      });
    }

    // get form inputs values
  function getFormData({ target }) {
    
    //console.log(target.value.replace(/[^0-9]/g, ""));
    setActiveData(target.value.replace(/[^0-9]/g, ""));
//    setphonenumber(target.value.replace(/[^0-9]/g, ""));
    setshowSub(false);
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }

  const subscribeHe = async e => {
    console.log('5555555');
      var phon_n = phonenumber;
     
      setLodaing(false);
      if(phon_n != null && phon_n !== "" && phon_n !== undefined){
         axios.get(`http://api.gamerclub.live/api/trendy-algeria/subscribe_he?msisdn=${phon_n}&sub_id=${sub_id}&plan=w&isHeader=yes&ti=${transactionid}`)
        .then(res => {
         //console.log(res);
         setLodaing(true);
          if (res.data.status == true) {
            tracker(phon_n);           
            notify("Subscribed Successfully");
            setTimeout(() => {
              navigate("/success", { replace: true });
            }, 2500);
                   
          } else {
            if (res.data.message != null) {
              if(res.data.code && res.data.code ==  "1"){
                //seterrorresverify(t('register.SUBSCRIBEERROR'));
                setErrorMsg('Already subscribed');
              }else{
                setErrorMsg('Something went wrong! Please make sure you have the correct number inserted or try again later.');
              }
              //setVerfiErrorMesg(true);

            }
            else if (res.data.blacklisted ) {  
        
              setErrorMsg('ce numéro de téléphone est sur liste noire, veuillez en utiliser un autre')
              
              }
            else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMsg("Something went wrong! Please make sure you have the correct verification code inserted or try again later.");
              //setVerfiErrorMesg(true);
            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setLodaing(true);

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });

      }
      else{
        const paramsurl = queryString.parse(window.location.search);
        //getRedirectURL(paramsurl);
      }
     
  };


  const Subscribe = async e => {
    //console.log(e.target.phone_number.value);
         e.preventDefault();

         const phoneInput = e.target.phone;
        
       
         // Proceed if phone number is valid
         const phone_number_val = phoneInput.value;
         setActiveData(phone_number_val);
    //console.log(activeData);
     
     if (phone_number_val) {
      console.log('phone')
       var phon_n = phone_number_val.toString();
       console.log(manipulateMsisdn(phon_n));
       const url = `http://api.gamerclub.live/api/trendy-algeria/send-otp?msisdn=${phon_n}&plan=d&ti=${transactionid}`
       setLodaing(false);
       await axios.get(url)
         .then(res => {
           if (res.data.status === true) {
             setShowverif(true)
           }
           else {
             if (res.data.msg != null) {
               setErrorMsg(res.data.msg); 
             }
              else if (res.data.blacklisted ) {  
        
          setErrorMsg('ce numéro de téléphone est sur liste noire, veuillez en utiliser un autre')
          
          }
             else {
              setErrorMsg(t("Something went wrong! Please make sure you have the correct number inserted or try again later.")); 
             }
            // setShowverif(true) 
 
           }
           setLodaing(true);
 
         })
         .catch(function (error) {
           setErrorMesg(true)
           setShowverif(false)
         })
         .finally(function () {
          //console.log('complete');
         });
 
 
     }
   };
 
 
   const verify = async e => {
     e.preventDefault();
     if (otp) {
       var phon_n = activeData;
       setLodaing(false);
       
       axios.get(`http://api.gamerclub.live/api/trendy-algeria/verify?msisdn=${phon_n}&pincode=${otp}&plan=w&ti=${transactionid}`)
         .then(res => {
          //console.log(res);
          setLodaing(true);
           if (res.data.status == true) {
            tracker(phon_n);
            gtag_report_conversion();
            notify("OTP Code Is Correct");
            setTimeout(() => {
              navigate("/success", { replace: true });
            }, 2500);
                
           } else {
           // tracker(phon_n);
             if (res.data.msg != null) {
              setErrorMsg(res.data.msg);
               //setVerfiErrorMesg(true);
 
             }
             else if (res.blacklisted ) {  
        
          setErrorMsg('ce numéro de téléphone est sur liste noire, veuillez en utiliser un autre')
          
          }
             else {
              setErrorMsg(t("Something went wrong! Please make sure you have the correct verification code inserted or try again later."));
               //----setVerfiErrorMesg(true);
 
 
             }
             // setVerfiErrorMesg(true);
             // seterrorresverify(res.data.msg);
           }
           setLodaing(true);
 
         })
         .catch(function (error) {
          //console.log(error);
           // setVerfiErrorMesg(true);
           // seterrorresverify(res.data.msg);
 
         })
         .finally(function () {
 
          //console.log('complete');
         });
     }
   };
 

  // send data to api
  function sendData(e) {
      // e.preventDefault();
      if(showSub){
        subscribeHe(e);
      }else{
        Subscribe(e);
      } 
    // e.preventDefault(); 
    // subscribeHe(e);
    // try {
    //   Subscribe(e);
    //   // setLodaing(false);
    //   // if(showSub){
    //   //   subscribeHe();
    //   // }
    //   // else{
    //   //   //Subscribe()
    //   //   setShowverif(true);
    //   // }
      
    //   /*
    //   await signupForm(formData);
    //   setErrorMsg("");
    //   localStorage.setItem("userPhone", formData.phone);
    //   notify("SignUp Successfully");
    //   */

    // } catch (error) {
    //   console.log(error);
    //   setErrorMsg(error.response?.data?.error);
    //   setLodaing(true);
    // }
  }
  const verifyOtp = async () => {
    try {
      setLodaing(false);
      // let formData = {
      //   phone: phone_number,
      //   otp: otp,
      // };
      // let result = await linkOtpCode(formData);
      // console.log(result);
      // // success alert message
      notify("OTP Code Is Correct");
      // localStorage.setItem("user_id", result?.user?.id);
      // // store token to local storage
      // localStorage.setItem("token", result?.access_token);
      // localStorage.setItem("userImage", JSON.stringify(result?.user?.avatar));
      notify("OTP Code Is Correct");
      setTimeout(() => {
        navigate("/success", { replace: true });
      }, 2500);
    } catch (error) {
      if (error) {
        //notify1(error?.response?.data?.message);
      }
      setLodaing(true);
      setOtp("");
    }
  };
 function gtag_report_conversion() {
    let tracker_value = localStorage.getItem('tracker');
      if(localStorage.getItem(tracker_value) != '1'){
        window.gtag('event', 'conversion', {
          'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
      });
      localStorage.setItem(tracker_value,'1')
    }
    return false;
  }

  return (
    <div className="signUp">
      <ToastContainer />
      <Row
        className="mx-0"
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <FormsAside side_img={side_img} />
        <Col xs={12} md={7} lg={8} xl={8} className="form">
          <Row className="h-20" style={{ paddingTop: "10%" }}>
            <Col className="m-auto" xs={12} md={8} lg={6}>

             
              {errorMsg ? (
                <div className="text-left alert alert-danger">
                  <p>{errorMsg}</p>
                </div>
              ) : (
                ""
              )}

              {!showverif ? (
               <>
                 <h1 style={{color:'black'}}>{t("create account")}</h1>

                 <form className="signUp-form" onSubmit={sendData}>
  <div className="w-100 d-flex justify-content-between">
   
    <input
     
     name="phone"
  value={activeData}
  onChange={getFormData}
      className="form-control ms-2 phone"
      type="tel"
      style={{width:'80%'}}
      placeholder={t("phone")}
      required
    />
     <input
      type="text"
      value="+213
      
"  style={{width:'21%',direction:'ltr'}}
      name="countryCode"
      className="form-control"
      readOnly
    />
  </div>
  <button
                      disabled={agree == true ? "" : "disabled"}
                      type="submit"
                      id="signupbut"
                      className="btn-submit"
                    >
                      {lodaing ? (
                        t("create account")
                      ) : (
                        <i className="fas fa-spinner fa-spin"></i>
                      )}
                    </button>
</form>

                <div className="submit-info my-5">
                    
                    <div className="d-flex align-items-center justify-content-center ">
                      <h6 className="form-check-label mb-0 fw-bold">Acceptation des termes et conditions</h6>
                      <input
                        type="checkbox"
                        className="form-check-input mt-0 me-2 check-box "
                        
                        onChange={(e) => {
                          setagree(!agree);
                        }}
                        checked={agree}
                      />
                    </div>

                    <div>
                      <p className="text-center fw-medium mb-0 mt-4">
                        Remarque : Vous serez abonné à Gamer Club avec votre numéro de
                        téléphone actuel
                      </p>
                      <p className="text-center fw-medium mb-0">
                        Les frais d'abonnement sont de 30,00 DZD par jour
                      </p>
                      <p className="text-center fw-medium mb-0">
                        Pour plus d'informations, veuillez nous contacter à
                        support@grandmstg.com{" "}
                      </p>
                    </div>
                    <h3>
                      {t("Already Have Account?")}{" "}
                      <Link to="/signin">{t("signin")}</Link>
                    </h3>
                  </div>       
                 </>           
              ) : null}

              {showverif ? (
                <OTPInput
                inputType="number"
                containerStyle={{ justifyContent: "center", direction: "ltr" }}  
                inputStyle={{
                  width: "40px",
                  height: "50px",
                  justifyContent: "space-between",
                  border: "2px solid black",
                  direction: "ltr"  
                }}
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input required {...props} />}
                shouldAutoFocus={true}
              />
              
              ) : null}

              {showverif ? (
                <div className="submit-info">
                  <button
                    onClick={(e) => verify(e)}
                    className="btn-submit w-100"
                  >
                    {lodaing ? (
                      t("Verify Otp")
                    ) : (
                      <i className="fas fa-spinner fa-spin"></i>
                    )}
                  </button>
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
