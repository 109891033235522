import Modal from "react-bootstrap/Modal";
import modal_icon from "../../assets/modal_icon.png";
import "./modals.css";
import {
  createTournamnet,
  getCreateTournamentDetails,
} from "../../Utilies/api";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { notify, notify1 } from "../ToastComponent";

// or via CommonJS
const Modal4 = ({ showModal4, setShowModal4, setMyTournaments }) => {
  // get user token
  const token = localStorage.getItem("token");
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));

  //state to store create tournament details
  const [createTournDetails, setCreateTournDetails] = useState([]);

  //   get create tournamnet details api
  const getCreateTournament = async (token) => {
    let result = await getCreateTournamentDetails(token);
    setCreateTournDetails(result.data);
  };

  // create tournamnet api
  const createTournamentSucces = async (
    entry_fee,
    game_id,
    name,
    players_number,
    unit,
    value,
    userId,
    token
  ) => {
    let formData = {
      entry_fee: entry_fee,
      game_id: game_id,
      name: name,
      players_number: players_number,
      unit: unit === "30" ? "m" : "hrs",
      value: value,
    };
    if (
      formData.entry_fee &&
      formData.name &&
      formData.players_number &&
      formData.value
    ) {
      let result = await createTournamnet(formData, userId, token);
      if (result.message === "success") {
        notify(result.message);
        setMyTournaments(result.data);
      } else {
        notify1(result.message);
      }
    } else {
      notify1("Tournamnet Inputs Required");
    }
  };
  const [inp, setInp] = useState("");
  const [inp1, setInp1] = useState("");
  const [inp2, setInp2] = useState("");
  const [inp3, setInp3] = useState("");
  const [inp4, setInp4] = useState("");

  const [entryFee, setEntryFee] = useState([]);
  const [winnerList, setWinnerList] = useState([]);

  // function asyncCreate() {
  //   let arr = [];
  //   let arr1 = [];
  //   for (
  //     let index = createTournDetails?.entryFee?.min;
  //     index <= createTournDetails?.entryFee?.max;
  //     index += createTournDetails?.entryFee?.min
  //   ) {
  //     arr.push(index);
  //     setEntryFee(arr);
  //   }
  //   for (
  //     let index = createTournDetails?.winners?.min;
  //     index <= createTournDetails?.winners?.max;
  //     index += createTournDetails?.winners?.min
  //   ) {
  //     arr1.push(index);
  //     setWinnerList(arr1);
  //   }
  // }

  useEffect(() => {
    getCreateTournament(token);
    function asyncCreate() {
      let arr = [];
      let arr1 = [];
      for (
        let index = createTournDetails?.entryFee?.min;
        index <= createTournDetails?.entryFee?.max;
        index += createTournDetails?.entryFee?.min
      ) {
        arr.push(index);
        setEntryFee(arr);
      }
      for (
        let index = createTournDetails?.winners?.min;
        index <= createTournDetails?.winners?.max;
        index += createTournDetails?.winners?.min
      ) {
        arr1.push(index);
        setWinnerList(arr1);
      }
    }
    setTimeout(() => {
      asyncCreate();
    }, 3000);
  });

  return (
    <>
      <Modal
        show={showModal4}
        onHide={() => setShowModal4(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <ToastContainer />
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            <MdOutlineClose
              className="modal-close-btn"
              onClick={() => setShowModal4(false)}
            />
            <Row>
              <Col xs={3} md={3} lg={3}></Col>
              <Col xs={6} md={6} lg={6}>
                <img className="w-100" src={modal_icon} alt="icon" />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xs={12} md={8} lg={8} className="m-auto">
              <div className="mb-3 modal-btns">
                {/* select game */}
                <select
                  defaultValue="default"
                  value={inp4}
                  onChange={(e) => setInp4(parseInt(e.target.value))}
                >
                  <option key="1" value="default" selected>
                    Game
                  </option>
                  {createTournDetails.games &&
                    createTournDetails.games.map((game) => (
                      <>
                        <option
                          key={game.id}
                          value={game?.id}
                          autoFocus={false}
                        >
                          {game?.game_name}
                        </option>
                      </>
                    ))}
                </select>
                {/* select game */}

                <input
                  type="text"
                  placeholder="Tournament Name"
                  className="mb-3 inp-tourn-name w-100"
                  value={inp}
                  onChange={(e) => setInp(e.target.value)}
                />

                {/* select tournament time */}
                <select
                  defaultValue="default"
                  value={inp1}
                  onChange={(e) => setInp1(e.target.value)}
                >
                  <option value="default" selected>
                    Time
                  </option>
                  {createTournDetails.times &&
                    createTournDetails.times.map((time) => (
                      <>
                        <option key={time.value} value={time?.value}>
                          {time?.value} {time?.unit}
                        </option>
                      </>
                    ))}
                </select>
                {/* select tournament time */}

                {/* select tournament entryfee */}
                <select
                  defaultValue="default"
                  value={inp2}
                  onChange={(e) => setInp2(parseInt(e.target.value))}
                >
                  <option value="default" selected>
                    Select The Number Of Tokens
                  </option>
                  {entryFee.map((fee, idx) => (
                    <option key={idx} value={fee}>
                      {fee}
                    </option>
                  ))}
                </select>
                {/* select tournament entryfee */}

                {/* select tournament winner */}
                <select
                  defaultValue="default"
                  value={inp3}
                  onChange={(e) => setInp3(parseInt(e.target.value))}
                >
                  <option value="default" selected>
                    numbers of winners
                  </option>
                  {winnerList &&
                    winnerList.map((winner, idx) => (
                      <>
                        <option key={idx} value={winner}>
                          {winner}
                        </option>
                      </>
                    ))}
                </select>
                {/* select tournament entryfee */}
              </div>
              <div
                className="mb-3 coin-btn"
                onClick={() => {
                  createTournamentSucces(
                    inp2,
                    inp4,
                    inp,
                    inp3,
                    inp1,
                    inp1,
                    userId,
                    token
                  );
                }}
              >
                create
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modal4;
