import "./PrivacyTerms.css";

const Terms = () => {
  return (
    <div>
      {" "}
      <body className="privacy">
        <h1>Gamer Club provided by Multiverse Terms and Conditions</h1>
        <p>
          <strong>Last Updated: July 9, 2024</strong>
        </p>

        <h2>Introduction</h2>
        <p>
          Welcome to Gamer Club provided by Multiverse . By using our services,
          you agree to comply with and be bound by the following terms and
          conditions. Please review these carefully. If you do not agree to
          these terms, you should not use our services.
        </p>

        <h2>Acceptance of Terms</h2>
        <p>
          By accessing or using any services provided by Gamer club provided by
          Multiverse , including gaming, health, entertainment, and other
          services (collectively referred to as “Services”), you acknowledge
          that you have read, understood, and agree to be bound by these terms
          and conditions (“Terms”).
        </p>

        <h2>Changes to Terms</h2>
        <p>
          Gamer Club provided by Multiverse reserves the right to modify these
          Terms at any time. Changes will be effective upon posting on our
          website or notification through other means. Your continued use of the
          Services after such modifications indicates your acceptance of the
          updated Terms.
        </p>

        <h2>Account Registration</h2>
        <p>
          To use certain features of the Services, you may need to create an
          account. You are responsible for maintaining the confidentiality of
          your account information and for all activities that occur under your
          account. You agree to provide accurate and complete information during
          registration and update it as necessary.
        </p>

        <h2>User Conduct</h2>
        <p>
          You agree not to use the Services for any unlawful or prohibited
          purposes, including:
        </p>
        <ul>
          <li>
            Violating any local, national, or international law or regulation.
          </li>
          <li>Interfering with the security or operation of the Services.</li>
          <li>
            Engaging in activities that may cause harm or damage to other users
            or Gamer Club provided by Multiverse .
          </li>
          <li>
            Distributing or sharing offensive, inappropriate, or harmful
            content.
          </li>
        </ul>

        <h2>Intellectual Property</h2>
        <p>
          All content, including but not limited to text, software, music,
          images, and graphics available on the Services, is owned by Gamer Club
          provided by Multiverse or its licensors. You may not copy, modify,
          distribute, or reproduce any content without express permission from
          Gamer Club provided by Multiverse .
        </p>

        <h2>Fees and Payments</h2>
        <p>
          in Services may require payment. By subscribing or making purchases
          through Gamer Club provided by Multiverse , you agree to pay any
          applicable fees as described in the relevant Service. All payments are
          non-refundable unless otherwise stated.
        </p>

        <h2>Privacy</h2>
        <p>
          Your use of the Services is also governed by our Privacy Policy, which
          explains how we collect, use, and protect your personal information.
          By using the Services, you agree to the terms of the Privacy Policy.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
          Gamer club provided by Multiverse is not responsible for any indirect,
          incidental, or consequential damages arising from your use of the
          Services. Your sole remedy for dissatisfaction with the Services is to
          stop using them.
        </p>

        <h2>Termination</h2>
        <p>
          We reserve the right to terminate or suspend your account or access to
          any or all of the Services at any time with or without notice for any
          reason, including breach of these Terms.
        </p>
      </body>
    </div>
  );
};
export default Terms;
