import React, { useEffect, useState } from "react";
import TournItem from "../TournItem/TournItem";
import "./myTournaments.css";
import { Col, Row } from "react-bootstrap";
import Loading from "../Loading/Loading";
import { getTournaments } from "../../Utilies/gamerClubApi";

const MyTournaments = () => {
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));
  // get user token
  const token = localStorage.getItem("token");

  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // get tournaments data
  async function getTournamentsItem(token) {
    try {
      let result = await getTournaments(token);
      setTournaments(result.items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTournamentsItem(token);
  }, [token]);

  return (
    <div>
      <div className="container mt-3">
        {isLoading ? (
          <div
            style={{ height: "100vh" }}
            className="justify-center d-flex align-items-center"
          >
            <Loading />
          </div>
        ) : (
          <Row className="p-0">
            {tournaments?.length > 0 ? (
              tournaments.map((tournament) => (
                <Col xs={12} md={12} lg={6} key={tournament.id}>
                  <TournItem tournament={tournament} />
                </Col>
              ))
            ) : (
              <h2
                // style={{ height: "50vh" }}
                className="text-center d-flex justify-content-center align-items-center empty-tourn"
              >
                No Tournaments Found
              </h2>
            )}
          </Row>
        )}
      </div>
    </div>
  );
};

export default MyTournaments;
