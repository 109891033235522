import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import "./GameItemDetails.css";
import "./playNowBtn.css";
import "./rateBtn.css";
import { getGameDetails } from "../../Utilies/api";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";

const AndroidGameDetails = () => {
  // get game id
  const { t } = useTranslation();
  let param = useParams();

  // get user token
  const token = localStorage.getItem("token");
  // get user id
  let userId = JSON.parse(localStorage.getItem("user_id"));

  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  // game details state
  const [gameDetails, setGameDetails] = useState("");
  console.log(gameDetails);
  async function gameItemDetails(gameId, token) {
    let result = await getGameDetails(gameId, token);
    setGameDetails(result);
  }

  useEffect(() => {
    gameItemDetails(param.gameId, token);
  }, [param.gameId, token]);

  return (
    <>
      <Navbar />
      <div
        className="game-details"
        style={{ paddingTop: "150px", paddingBottom: "50px" }}
      >
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <SideMenuBar showSideBar={showSideBar} sideBar={sideBar} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} lg={4}>
              <img
                className="w-100 gameDetails-img"
                src={gameDetails?.photo}
                alt={gameDetails?.name}
              />
              {/* <span className="game-date">Starts in 1D 10H 21M</span>
              <span>2/11/2022 :00 PM EDT</span> */}
            </Col>
            <Col xs={12} md={12} lg={8} className="game-section">
              <div className="game-heading">
                <h2>{gameDetails?.name}</h2>
                {/* <p className="mt-4 game-level">
                  Category : {gameDetails.game_cat} - Likes :{" "}
                  {gameDetails.likes_count} - Play Count :{" "}
                  {gameDetails.play_count}
                </p> */}
              </div>
              <div className="game-info">
                <p>
                  {gameDetails?.description?.slice(0, 500).split(" ").join(" ")}
                  ...
                </p>
              </div>
              <Row className="game-btns">
                <Col xs={6} md={4} lg={3}>
                  <Link
                    className="mb-3 play-button"
                    to={token ? gameDetails?.folder : "/signin"}
                    download={gameDetails?.folder}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="2em"
                      height="2em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke-width="2"
                        d="M12 15V3m0 12l-4-4m4 4l4-4M2 17l.621 2.485A2 2 0 0 0 4.561 21h14.878a2 2 0 0 0 1.94-1.515L22 17"
                      ></path>
                    </svg>
                    {t("download")}
                  </Link>
                </Col>
                <Col xs={6} md={4} lg={3}>
                  <button
                    onClick={() => {
                      navigate("/android");
                    }}
                    className="mb-3 play-button"
                  >
                    {t("back")}
                  </button>
                </Col>
                <Col xs={6} md={4} lg={3}>
                  <button
                    className="mb-3 rate-btn"
                    onClick={() => navigate("/rateUs")}
                  >
                    <svg
                      height={24}
                      width={24}
                      fill="#FFFFFF"
                      viewBox="0 0 24 24"
                      data-name="Layer 1"
                      id="Layer_1"
                      className="sparkle"
                    >
                      <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z" />
                    </svg>
                    {t("rate us")}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AndroidGameDetails;
