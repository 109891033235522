import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SideMenuBar from "../../Components/SideMenuBar/SideMenuBar";
import { getAndroidGames, searchEngine } from "../../Utilies/api";
import star_empty from "../../assets/html5GamesPage/star_empty.png";
import star_fill from "../../assets/html5GamesPage/star_fill.png";
import "./androidGamesPage.css";
import AndroidItem from "../../Components/AndroidItem/AndroidItem";
import SearchInput from "../../Components/SearchInput/SearchInput";
import { ToastContainer } from "react-toastify";
import Navbar from "../../Components/Navbar/NavbarComp";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import {
  getGamesCategoriesApi,
  getGamesListApi,
} from "../../Utilies/gamerClubApi";

const AndroidGamesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  // store user details in state
  const [index, setIndex] = useState("strategy");
  const [isLoading, setIsLoading] = useState(true);
  const [gamesCategories, setGamesCategories] = useState([]);
  const [gamesList, setGamesList] = useState([]);
  // get user id
  const getGamesCategories = async () => {
    try {
      const response = await getGamesCategoriesApi("select-android");
      setGamesCategories(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  // search functionality
  const [queryInput, setQueryInput] = useState("");
  const getGamesList = async (cat_name, search) => {
    try {
      const response = await getGamesListApi("android", cat_name, search);
      setGamesList(response.items);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGamesCategories();
    getGamesList(index, queryInput);
  }, [index, isLoading, queryInput]);

  return (
    <>
      <Navbar title={t("android games")} />
      <div className="androidGames" style={{ paddingTop: "125px" }}>
        <div className="container">
          <SideMenuBar sideBar={sideBar} showSideBar={showSideBar} />
        </div>
        {/* android games */}
        <Container fluid>
          <ToastContainer />
          <Row>
            <Col xs={12} md={12} lg={9}>
              <swiper-container navigation="true" pagination="false">
                {gamesList.map((game) => (
                  <swiper-slide key={game.id}>
                    <div
                      className="mx-2 mb-3 swipper-slider"
                      style={{
                        backgroundImage: `url(${game?.photo})`,
                      }}
                    >
                      <Row className="h-100">
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          className="swiper-content"
                          style={{ background: "rgba(0, 0, 0, 0.3)" }}
                        >
                          <div className="stars">
                            <img src={star_fill} alt="star" />
                            <img src={star_fill} alt="star" />
                            <img src={star_fill} alt="star" />
                            <img src={star_fill} alt="star" />
                            <img src={star_empty} alt="star" />
                          </div>
                          <h1>{game.name}</h1>
                          <p style={{ width: "80%" }}>
                            {game.description
                              .slice(0, 250)
                              .split(" ")
                              .join(" ")}
                            ....
                          </p>
                          <Link
                            to={`/androidGameDetails/${game.id}`}
                            className="btn-play"
                          >
                            {t("download now")}
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </swiper-slide>
                ))}
              </swiper-container>
            </Col>
            {/* side games */}
            <Col xs={12} md={12} lg={3}>
              <div className="side-games">
                <Row className="m-auto w-100">
                  {gamesList.map((topGame, index) => (
                    <Col xs={12} md={6} lg={12} key={index}>
                      <div
                        className="mb-2 side-game active"
                        style={
                          index === 2
                            ? { backgroundColor: "rgba(58, 20, 78, 1)" }
                            : { backgroundColor: "#252525" }
                        }
                      >
                        <Row
                          onClick={() =>
                            navigate(`/androidGameDetails/${topGame.id}`)
                          }
                          className="p-0 m-0"
                        >
                          <Col xs={4} md={3} lg={3} className="p-0 text-center">
                            <img
                              className="side-game-img"
                              src={topGame.thumb}
                              alt="game_photo"
                              loading="lazy"
                            />
                          </Col>
                          <Col
                            xs={8}
                            md={9}
                            lg={9}
                            className="col-sm-10 col-md-9 side-game-info"
                          >
                            <h5>{topGame?.name}</h5>
                            <p>
                              {topGame?.description
                                .slice(0, 50)
                                .split(" ")
                                .join(" ")}
                              ....
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        {/* games section */}
        <Container fluid>
          <div className="mx-2 games-heading">
            <h1>{t("explore")}</h1>
            <div className="games-tabs">
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <SearchInput
                    queryInput={queryInput}
                    setQueryInput={setQueryInput}
                    searchPlaceholder={t("search...")}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        <Container fluid>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="mt-4 games-items">
              <div className="categories-menu">
                {gamesCategories.map((category, idx) => (
                  <h1
                    className={`category-name ${
                      category === index ? "active" : ""
                    }`}
                    key={idx}
                    onClick={() => {
                      setIsLoading(!isLoading);
                      getGamesList(category);
                      setIndex(category);
                    }}
                  >
                    {category === "undefined" ? "Other" : category}
                  </h1>
                ))}
              </div>
              <Row className="mb-5">
                {gamesList?.length > 0 ? (
                  gamesList.map((gameItem) => {
                    return (
                      <Col
                        xs={6}
                        md={4}
                        lg={3}
                        xl={3}
                        xxl={2}
                        key={gameItem.id}
                        className="mb-3"
                      >
                        <AndroidItem
                          android_img={gameItem.thumb}
                          game_name={gameItem.name}
                          gameItemId={gameItem.id}
                          android_btn={t("download now")}
                          imgNavigate={"androidGameDetails"}
                          btnNavigate={"androidGameDetails"}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <p className="empty-games">{t("no games found")}</p>
                )}
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default AndroidGamesPage;
