import React from "react";
import { useSearchParams } from "react-router-dom";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";
import PaymentFailed from "../PaymentFailed/PaymentFailed";

const DeafultPayment = () => {
  const [searchParams] = useSearchParams();

  // Get a specific query parameter
  const success = searchParams.get("success");
  return (
    <>
      {success == 1 ? (
        <PaymentSuccess />
      ) : success == 0 ? (
        <PaymentFailed />
      ) : (
        ""
      )}
    </>
  );
};

export default DeafultPayment;
