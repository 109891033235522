import React, { useEffect, useState } from "react";
import VoucherCard from "../VoucherCard/VoucherCard";
import { Col, Container, Row } from "react-bootstrap";
import { postCartItem } from "../../Utilies/api";
import { notify } from "../ToastComponent";
import { useTranslation } from "react-i18next";
import { getUserVouchers } from "../../Utilies/gamerClubApi";

const Voucher = ({ toggle }) => {
  const { t } = useTranslation();
  const [userVoucher, setUserVoucher] = useState([]);
  // get user token
  const token = localStorage.getItem("token");

  async function userVouchers(token) {
    try {
      let result = await getUserVouchers(token);
      setUserVoucher(result.data);
    } catch (error) {}
  }

  // add to cart
  // async function addToCart(sectionType, token, productId) {
  //   let formData = {
  //     player_id: userId,
  //     voucher_id: productId,
  //     qty: 1,
  //   };
  //   let result = await postCartItem(formData, sectionType, token);
  //   if (result.status === true) {
  //     notify("Your Item Has Been Added Successfully");
  //   }
  // }

  useEffect(() => {
    userVouchers(token);
  }, [token]);

  return (
    <div className={toggle === 1 ? "show-content game-voucher" : "content"}>
      <Row className="my-3">
        {userVoucher.length > 0 ? (
          userVoucher.map((userVouch, idx) => (
            <Col key={idx} xs={12} md={6} lg={4} xxl={3} className="mb-3">
              <VoucherCard
                userVouch={userVouch}
                // addToCart={addToCart}
                token={token}
              />
            </Col>
          ))
        ) : (
          <Col xs={12} md={12} lg={12} className="mb-3">
            <h2 className="empty-cart">
              {t("No Vouchers In Your Voucher List !")}
            </h2>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Voucher;
