import React from "react";
import "./header.css";
// import logo from "../../assets/logo.svg";
// import { Link } from "react-router-dom";
// import play_store from "../../assets/google_store.png";
// import google_store from "../../assets/app_Store.png";

const Header = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="hero-section">
          {/* <img src={logo} alt="logo" className="logo" />
          <h2>
            <span>Available Now</span>
          </h2> */}
          {/* <div className="playit-links">
            <Link
              to="https://play.google.com/store/apps/details?id=com.gt.pi"
              target="_blank"
            >
              <img src={play_store} alt="play_store" />
            </Link>
            <Link
              to="https://apps.apple.com/eg/app/playit-pi/id1555945981"
              target="_blank"
            >
              <img src={google_store} alt="app_store" />
            </Link>
          </div> */}
          {/* <div className="mb-5 btn-join">
            <span>join now</span>
            <img src={coin} alt="coin name" />
            <span>50</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
