import React, { useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { BarcodeImg, getPosRefNum, getUserData ,purchaseVoucher} from "../../Utilies/api";
import { notify1 } from "../ToastComponent";
import { GoCopy } from "react-icons/go";

import { useTranslation } from "react-i18next";
import tokenIcon from "../../assets/gamer_token.svg";
import { useNavigate } from "react-router-dom";
const RedeemVoucher = ({ category ,serialNumber}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const copyToClipboard = () => {
    debugger
    const voucherText = document.getElementById("voucherText").textContent;
    navigator.clipboard.writeText(voucherText)
      
  };
 
  return (
    <>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <img
            className="mb-3 w-100"
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "20px",
            }}
            src={category.productImage}
            alt="itemship"
          />
        </Col>
        <Col xs={12} md={12} lg={12}>
        
          <div className=" d-flex flex-column text-center justify-content-center w-100 align-items-center">
            <p className="my-3 text-white text-center">
            {category.productName}
            </p>
          <p className="mb-3 member-price text-center">
            
            <span id="voucherText" className="text-dark">{serialNumber}</span>

            <button onClick={()=>copyToClipboard()} title="Copy to clipboard" className="btn text-dark"><GoCopy /></button>
  
          </p>
          </div>
          
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} className="payment-tabs text-center">
          {/* <SinglePayment live={categoryVouchers[index]} qty={"1"} /> */}
          <button
          onClick={()=> navigate("/home")}
            className="btn"
          >
            {t("Close")}
          </button>
        </Col>
      </Row>
    </>
   
  );
};

export default RedeemVoucher;
