// import axios from 'axios';

// // Create an axios instance
// const api = axios.create();

// // Interceptor to modify request URLs based on domain protocol
// api.interceptors.request.use((config) => {
//     console.log('interceptor',protocol)
//   const { protocol } = window.location; // Get current domain protocol
//   // Check if API URL should be HTTP or HTTPS
//   if (protocol === 'https:') {
//     if (!config.url.startsWith('https')) {
//       config.url = config.url.replace(/^http:/, 'https:');
//     }
//   } else if (protocol === 'http:') {
//     if (!config.url.startsWith('http')) {
//       config.url = config.url.replace(/^https:/, 'http:');
//     }
//   }

//   return config;
// }, (error) => {
//   // Handle request error
//   return Promise.reject(error);
// });

// export  {api}
// ;


import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://dummyjson.com', // Replace with your API base URL
//   timeout: 1000,
  headers: { 'Content-Type': 'application/json' }
}); // Create the axios instance

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before the request is sent
    console.log(`*******************************************************`)
   
    const { protocol } = window.location; 
    console.log(protocol)// Get current domain protocol
//   Check if API URL should be HTTP or HTTPS
        if (protocol === 'https:') {
            console.log('https')
            if (!config.url.startsWith('https')) {
            config.url = config.url.replace(/^http:/, 'https:');
            }
        } else if (protocol === 'http:') {
            console.log('Interceptor using: http')
            if (!config.url.startsWith('http:')) {
            config.url = config.url.replace(/^https:/, 'http:');
            console.log(config.url,'url')
            }
        }
    return config;
  },
  function (error) {
    // Handle the error
    return Promise.reject(error);
  }
);

// export default axiosInstance;

export {axiosInstance as axios};
